import React, { useEffect, useState, useCallback } from "react";
import { Close, Plus, Remove } from "../../components/svgIcons";
import { Spin, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { isEmpty, size, orderBy } from "lodash";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Drawer } from "antd";
import moment from "moment-timezone";

import { CSVLink } from "react-csv";

export default function CampaignComponent(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [campaignDetails, setCampaignDetails] = useState({});
  const [isCalled, setCalledApi] = useState(false);
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSearchNumber, setPageSearchNumber] = useState(1);
  const [count, setCount] = useState(1000);
  const [searchCount, setSearchCount] = useState(50);
  const [tab, setTab] = useState("overview");
  const [campaignData, setCampaignData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [placement, setPlacement] = useState("right");
  const [campaignUrlData, setCampaignUrlData] = useState([]);
  const [campaignUrlDataLoader, setCampaignUrlDataLoader] = useState(false);
  const [totalSearchCount, setTotalSearchCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [campaignSearchData, setCampaignSearchData] = useState([]);
  const [campaignSearchLoader, setCampaignSearchLoader] = useState(true);
  const [nextStep, setNextStep] = useState(0);
  const [isExportApiCalled, setIsExportApiCalled] = useState(true);
  const [campaignExportData, setCampaignExportData] = useState([]);
  const [previousPathName, setPreviousPathName] = useState("");
  const [previousTab, setPreviousTab] = useState("");
  const params = useParams();
  // Slect End
  useEffect(() => {
    if (location.pathname.includes("overview")) {
      changeTab("overview");
    } else {
      changeTab("search");
    }
    if (!isCalled) {
      console.log(location, "location?.state?.pathname==>");
      getCampaignDetailsById();
      setCampaignDetails(location?.state?.data);
      setPreviousTab(location?.state?.campaignType);
      setPreviousPathName(location?.state?.pathname);
      setCalledApi(true);
    }
  }, [campaignDetails]);

  useEffect(() => {}, [campaignDetails]);

  const getCampaignDetailsById = async () => {
    if (location?.state?.pathname == "/free-form-emails") {
      const { value } = await props.GetCampaignDetailsById({
        id: params.id,
        type: "FreeForm",
      });
      if (value?.Success) {
        setCampaignDetails({ ...value?.Result });
        let clear = setInterval(() => {
          callSearchApi("", value?.Result);
          setCampaignSearchLoader(false);
          callApi(value?.Result);
          clearInterval(clear);
        }, 1000);
      }
    } else {
      const { value } = await props.GetCampaignDetailsById({
        id: params.id,
        type: "",
      });
      if (value?.Success) {
        setCampaignDetails({ ...value?.Result });
        let clear = setInterval(() => {
          callSearchApi("", value?.Result);
          setCampaignSearchLoader(false);
          callApi(value?.Result);
          clearInterval(clear);
        }, 1000);
      }
    }
  };

  const onCancel = () => {
    console.log(previousPathName, "---", previousTab);
    if (previousPathName === "/free-form-emails" && previousTab === "Test") {
      navigate("/free-form-emails?campaign=Test");
    } else if (
      previousPathName === "/free-form-emails" &&
      previousTab === "Live"
    ) {
      navigate("/free-form-emails?campaign=Live");
    } else if (
      previousPathName === "/iim-analytics" &&
      previousTab === "Test"
    ) {
      navigate("/iim-analytics?campaign=Test");
    } else if (
      previousPathName === "/iim-analytics" &&
      previousTab === "Live"
    ) {
      navigate("/iim-analytics?campaign=Live");
    } else {
      if (campaignDetails?.name?.includes("FreeFormLive")) {
        navigate("/free-form-emails?campaign=Live");
      } else if (campaignDetails?.name?.includes("FreeFormTest")) {
        navigate("/free-form-emails?campaign=Test");
      } else if (campaignDetails?.name?.includes("--test")) {
        navigate("/iim-analytics?campaign=Test");
      } else if (
        campaignDetails?.name?.includes("--live") ||
        campaignDetails?.name?.includes("-live")
      ) {
        navigate("/iim-analytics?campaign=Live");
      }
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setSelectedData({});
    setCampaignUrlData([]);
    setOpen(false);
  };

  const pageChange = async (key) => {
    setCampaignSearchLoader(true);
    if (key === "next") {
      let pageNum = pageSearchNumber + 1;
      setPageSearchNumber(pageNum);
      let payload = {};
      if (searchText !== "") {
        payload = {
          page: pageNum,
          count: searchCount,
          category: campaignDetails?.Category,
          searchText: searchText,
        };
      } else {
        payload = {
          page: pageNum,
          count: searchCount,
          category: campaignDetails?.Category,
          // searchText: " "
        };
      }
      const { value } = await props.CampaignSearch(payload);
      if (value?.Success) {
        setCampaignSearchLoader(false);
        if (value?.Data?.length > 0) {
          setCampaignSearchData([...value?.Data]);
          setTotalSearchCount(value?.TotalCCallount);
          let nextPageCount = parseInt(
            Math.ceil(value?.TotalCount / searchCount)
          );
          setNextStep(nextPageCount);
        } else {
          setCampaignSearchData([]);
          setTotalSearchCount(0);
        }
      } else {
        setCampaignSearchData([]);
        setTotalSearchCount(0);
        setCampaignSearchLoader(false);
      }
    } else if (key === "previous") {
      let pageNum = pageSearchNumber - 1 === 0 ? 1 : pageSearchNumber - 1;
      setPageSearchNumber(pageNum);
      let payload = {};

      if (searchText !== "") {
        payload = {
          page: pageNum,
          count: searchCount,
          category: campaignDetails?.Category,
          searchText: searchText,
        };
      } else {
        payload = {
          page: pageNum,
          count: searchCount,
          category: campaignDetails?.Category,
          // searchText: " "
        };
      }
      const { value } = await props.CampaignSearch(payload);
      if (value?.Success) {
        if (value?.Data?.length > 0) {
          setCampaignSearchData([...value?.Data]);
          setTotalSearchCount(value?.TotalCount);
          let nextPageCount = parseInt(
            Math.ceil(value?.TotalCount / searchCount)
          );
          setNextStep(nextPageCount);
        } else {
          setCampaignSearchData([]);
          setTotalSearchCount(0);
        }
        setCampaignSearchLoader(false);
      } else {
        setCampaignSearchData([]);
        setTotalSearchCount(0);
        setCampaignSearchLoader(false);
      }
    }
    setLoader(false);
  };

  const callExportData = async (totalCount, data) => {
    let payload = {
      page: 1,
      count: totalCount,
      category: data?.Category,
    };
    const { value } = await props.CampaignSearch(payload);
    if (value?.Success) {
      if (value?.Data?.length > 0) {
        setCampaignExportData([...value?.Data]);
      }
    }
  };

  const callSearchApi = async (e = "", data = {}) => {
    if (!isEmpty(e)) {
      setSearchText(e.target.value);
    }
    let payload = {};
    setCampaignSearchLoader(true);
    if (!isEmpty(e) && e.target.value !== "") {
      payload = {
        page: 1,
        count: searchCount,
        category: isEmpty(data) ? campaignDetails?.Category : data?.Category,
        searchText: e.target.value,
      };
      setPageSearchNumber(1);
    } else {
      payload = {
        page: pageSearchNumber,
        count: searchCount,
        category: isEmpty(data) ? campaignDetails?.Category : data?.Category,
        // searchText: " "
      };
    }
    const { value } = await props.CampaignSearch(payload);
    if (value?.Success) {
      if (value?.Data?.length > 0) {
        setCampaignSearchData([...value?.Data]);
        setTotalSearchCount(value?.TotalCount);
        data = isEmpty(data) ? campaignDetails : data;
        if (isExportApiCalled) {
          setIsExportApiCalled(false);
          callExportData(value?.TotalCount, data);
        }
        let nextPageCount = parseInt(
          Math.ceil(value?.TotalCount / searchCount)
        );
        setNextStep(nextPageCount);
      } else {
        setCampaignSearchData([]);
        setTotalSearchCount(0);
      }
      setCampaignSearchLoader(false);
    } else {
      setCampaignSearchData([]);
      setTotalSearchCount(0);
      setCampaignSearchLoader(false);
    }
  };

  const callApi = async (data) => {
    const { value } = await props.getCampaignClickInfo({
      page: page,
      count: count,
      category: data?.Category,
    });
    if (value?.length > 0) {
      let data = orderBy(value, ["Id"], ["desc"]);
      setCampaignData([...data]);
      setLoader(false);
    } else {
      setCampaignData([]);
      setLoader(false);
    }
  };

  const headers = [
    { label: "First Name", key: "FirstName" },
    { label: "Last Name", key: "LastName" },
    { label: "Email", key: "Email" },
    { label: "Event Action", key: "Event" },
    { label: "Click URL", key: "Url" },
    { label: "Date Time", key: "DateTime" },
  ];

  const getCampaignClickUrlsInfo = async (item) => {
    setCampaignUrlDataLoader(true);
    const { value } = await props.getCampaignClickUrlsInfo({
      page: 1,
      count: 100000,
      urlId: item?.Id,
      Url: item?.URL,
    });
    if (value?.length > 0) {
      setCalledApi(true);
      setCampaignUrlData([...value]);
      setCampaignUrlDataLoader(false);
    } else {
      setCampaignUrlDataLoader(false);
    }
  };

  const changeTab = (tab) => {
    const newLocationState = {
      pathname: location.state?.pathname,
      campaignType: location.state?.campaignType,
    };
    if (tab === "overview") {
      navigate(`/campaign/overview/${params.id}`, { state: newLocationState });
    } else {
      navigate(`/campaign/search/${params.id}`, { state: newLocationState });
    }
    setTab(tab);
  };
  const optimizedUserFn = useCallback(debounce(callSearchApi), []);

  const getConvertTimeToEast = (date) => {
    console.log(moment?.tz?.guess(), date, "<<<<timezozne");
    // timestamp.setHours(timestamp.getHours() + 5); // Add 5 hours
    // timestamp.setMinutes(timestamp.getMinutes() + 30);

    if (typeof date === "string" && !date?.includes("Z")) {
      date = date + "Z";
    }

    const easternTime = moment(date)
      .tz("America/New_York")
      .format("MM/DD/yyyy hh:mm A");

    return easternTime;
  };

  return (
    <>
      <main
        className="main viewother__news-wrapper"
        style={{ paddingBottom: "90px" }}
        role="head"
      >
        <div className="main__header">
          <header className="head">
            <div className="head__container">
              <div className="head__row">
                <div className="head__left">
                  <div className="head__close">
                    <button
                      onClick={() => onCancel()}
                      className="btn btn__close"
                    >
                      <Close />
                    </button>
                  </div>
                  <div className="head__divider"></div>
                  <div className="head__title">
                    {campaignDetails?.CampaignName
                      ?.replaceAll("PlainTextTest--FreeFormTest","")
                      .replaceAll("PlainTextLive--FreeFormLive","")
                      .replaceAll("--test", "")
                      .replaceAll("--live", "")
                      .replaceAll("--Live", "")
                      .replaceAll("--Test", "")
                      .replaceAll("-live", "")
                      .replaceAll("FreeFormTest", "")
                      .replaceAll("FreeFormLive", "")
                      .replaceAll("---", "")
                      .replaceAll("PlainTextLive", "")
                      .replaceAll("PlainTextTest","")
                      .replaceAll("-", "")}
                  </div>
                </div>
              </div>
            </div>
          </header>
        </div>
        <div className="tabs__links tabs__links--space">
          <button
            className={tab === "overview" ? "tabs__link active" : "tabs__link"}
            onClick={() => changeTab("overview")}
          >
            Overview
          </button>
          <button
            className={tab === "search" ? "tabs__link active" : "tabs__link"}
            onClick={() => changeTab("search")}
          >
            Search
          </button>
        </div>
        {tab === "overview" && (
          <div className="custom__container">
            <div className="table-primary-block">
              <div className="d-flex justify-content-between align-items-center">
                <div className="title-block">
                  {campaignDetails?.DeliveredCount !== null
                    ? parseInt(campaignDetails?.DeliveredCount)?.toLocaleString(
                        "en-US"
                      )
                    : "0"}{" "}
                  sent
                </div>
                <div className="tpb-child">
                  <span>OPEN RATE</span>
                  <h5>
                    {campaignDetails && campaignDetails?.OpenedCount !== null
                      ? parseInt(
                          (campaignDetails?.OpenedCount /
                            campaignDetails?.DeliveredCount) *
                            100
                        ) === 0
                        ? parseFloat(
                            (campaignDetails?.OpenedCount /
                              campaignDetails?.DeliveredCount) *
                              100
                          )
                        : parseFloat(
                            (campaignDetails?.OpenedCount /
                              campaignDetails?.DeliveredCount) *
                              100
                          )
                            .toFixed(2)
                            ?.toLocaleString("en-US")
                      : "0"}
                    %{/* %):("0%")} */}
                  </h5>

                  <h6>
                    {campaignDetails?.OpenedCount !== null
                      ? parseInt(campaignDetails?.OpenedCount)?.toLocaleString(
                          "en-US"
                        )
                      : "0"}{" "}
                    opens
                  </h6>
                </div>
                <div className="tpb-child">
                  <span>UNIQUE OPEN RATE</span>
                  <h5>
                    {campaignDetails?.UniqueOpenCount !== null
                      ? parseInt(
                          (campaignDetails?.UniqueOpenCount /
                            campaignDetails?.DeliveredCount) *
                            100
                        ) === 0
                        ? parseFloat(
                            (campaignDetails?.UniqueOpenCount /
                              campaignDetails?.DeliveredCount) *
                              100
                          )
                        : parseFloat(
                            (campaignDetails?.UniqueOpenCount /
                              campaignDetails?.DeliveredCount) *
                              100
                          )
                            .toFixed(2)
                            ?.toLocaleString("en-US")
                      : "0"}
                    %
                  </h5>
                  <h6>
                    {campaignDetails?.UniqueOpenCount !== null
                      ? parseInt(
                          campaignDetails?.UniqueOpenCount
                        )?.toLocaleString("en-US")
                      : "0"}{" "}
                    unique opens
                  </h6>
                </div>
                {/* <div className="tpb-child">
                  <span>CLICK RATE</span>
                  <h5>
                    {campaignDetails?.stats?.length > 0 &&
                    campaignDetails?.stats[0].stats !== null ?(
                      (parseInt(
                        (campaignDetails?.stats[0].stats?.clicks /
                          campaignDetails?.stats[0].stats?.delivered) *
                          100
                      ) === 0
                        ? parseFloat(
                            (campaignDetails?.stats[0].stats?.clicks /
                              campaignDetails?.stats[0].stats?.delivered) *
                              100
                          )
                        : parseFloat(
                            (campaignDetails?.stats[0].stats?.clicks /
                              campaignDetails?.stats[0].stats?.delivered) *
                              100
                          )
                            .toFixed(2)
                            ?.toLocaleString("en-US"))):("0")}%
                  </h5>
                  <h6>
                    {campaignDetails?.stats?.length > 0 &&
                    campaignDetails?.stats[0].stats !== null ?(
                      parseInt(
                        campaignDetails?.stats[0].stats?.clicks
                     )?.toLocaleString("en-US")):("0")}{" "}
                    clicks
                  </h6>
                </div> */}
                <div className="tpb-child">
                  <span>UNIQUE CLICK RATE</span>
                  <h5>
                    {campaignDetails?.UniqueClickCount !== null
                      ? parseInt(
                          (campaignDetails?.UniqueClickCount /
                            campaignDetails?.DeliveredCount) *
                            100
                        ) === 0
                        ? parseFloat(
                            (campaignDetails?.UniqueClickCount /
                              campaignDetails?.DeliveredCount) *
                              100
                          )
                        : parseFloat(
                            (campaignDetails?.UniqueClickCount /
                              campaignDetails?.DeliveredCount) *
                              100
                          )
                            .toFixed(2)
                            ?.toLocaleString("en-US")
                      : "0"}
                    %
                  </h5>
                  <h6>
                    {campaignDetails?.UniqueClickCount !== null
                      ? parseInt(
                          campaignDetails?.UniqueClickCount
                        )?.toLocaleString("en-US")
                      : "0"}{" "}
                    unique clicks
                  </h6>
                </div>
                <div className="tpb-child">
                  <span>UNOPENED</span>
                  <h5>
                    {campaignDetails?.UniqueOpenCount !== null
                      ? parseInt(
                          (campaignDetails?.UniqueOpenCount /
                            campaignDetails?.DeliveredCount) *
                            100
                        ) === 0
                        ? parseFloat(
                            (Math.abs(
                              campaignDetails?.UniqueOpenCount -
                                campaignDetails?.DeliveredCount
                            ) /
                              campaignDetails?.DeliveredCount) *
                              100
                          )
                        : parseFloat(
                            (Math.abs(
                              campaignDetails?.UniqueOpenCount -
                                campaignDetails?.DeliveredCount
                            ) /
                              campaignDetails?.DeliveredCount) *
                              100
                          )
                            .toFixed(2)
                            ?.toLocaleString("en-US")
                      : "0"}
                    %
                  </h5>
                  <h6>
                    {campaignDetails?.DeliveredCount !== null
                      ? parseInt(
                          campaignDetails?.DeliveredCount -
                            campaignDetails?.UniqueOpenCount
                        )?.toLocaleString("en-US")
                      : "0"}{" "}
                    unopened
                  </h6>
                </div>
              </div>
            </div>
            <div className="page__header">
              <div className="page__header--container">
                <div className="page__header--row align-items-center">
                  <div className="page__header--left">
                    <h1 className="page__header--title">Clicked Links</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="custom__container">
              <div className="table-responsive table__responsive--custom word__table_break">
                <table className="table table__custom">
                  <thead>
                    {campaignData.length > 0 && (
                      <tr>
                        <th>Url</th>
                        <th>Times Clicked</th>
                        <th></th>
                      </tr>
                    )}
                    {campaignData.length == 0 && (
                      <tr>
                        <th>Url</th>
                        <th className="text-end">Times Clicked</th>
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {loader ? (
                      <Spin
                        size="large"
                        indicator={
                          <LoadingOutlined
                            style={{ fontSize: 30, color: "#000" }}
                            spin
                          />
                        }
                        className="loader__full"
                      />
                    ) : (
                      campaignData.length > 0 &&
                      campaignData?.map((item, key) => {
                        return (
                          <tr>
                            <td className="table__status">
                              <button
                                onClick={() => window.open(`${item.URL}`)}
                                className="text-btn"
                              >
                                {item.URL}
                              </button>
                            </td>
                            <td
                              onClick={() => {
                                getCampaignClickUrlsInfo(item);
                                showDrawer();
                                setSelectedData(item);
                              }}
                              style={{
                                "text-decoration": "underline",
                                color: "var(--purple)",
                                cursor: "pointer",
                              }}
                              className="text-btn"
                            >
                              {item.TimesClicked}
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
                {campaignData?.length === 0 && !loader && (
                  <div className="table__blank">
                    <div className="table__blank--text">No Data</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {tab === "search" && (
          <div className="custom__container">
            <div className="page__header">
              <div className="page__header--container">
                <div className="page__header--row align-items-center">
                  <div className="page__header--left">
                    <h1 className="page__header--title">Search</h1>
                  </div>
                  <div className="page__header--right">
                    {campaignExportData.length > 0 && (
                      <CSVLink
                        data={campaignExportData?.map((item) => {
                          return {
                            ...item,
                            ...{
                              Event:
                                item.Event === "click"
                                  ? "clicked"
                                  : item.Event === "open"
                                  ? "opened"
                                  : item.Event,
                              DateTime:
                                // moment(
                                //   new Date(
                                //     new Date(item.DateTime).setHours(
                                //       new Date(item.DateTime).getHours() - 5
                                //     )
                                //   )
                                // ).format("MM/DD/yyyy HH:mm A") + "(ET)",
                                getConvertTimeToEast(item.DateTime) + "(ET)",
                            },
                          };
                        })}
                        headers={headers}
                        filename="CampaignEventData.csv"
                        className="btn btn__purple"
                        target={"_blank"}
                      >
                        Export Click Data
                      </CSVLink>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group mt-2">
              <input
                placeholder="Search by first name, last name, or email..."
                className="form-control"
                value={searchText}
                type="text"
                name="searchText"
                onChange={(e) => {
                  // console.log(e.target.value)
                  setSearchText(e.target.value);
                  optimizedUserFn(e, campaignDetails);
                }}
              />
            </div>
            <div className="custom__container">
              <div className="table-responsive table__responsive--custom word__table_break">
                <table className="table table__custom">
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Event Action</th>
                      <th>Date</th>
                      <th>Link Url</th>
                    </tr>
                  </thead>
                  <tbody>
                    {campaignSearchLoader ? (
                      <Spin
                        size="large"
                        indicator={
                          <LoadingOutlined
                            style={{ fontSize: 30, color: "#000" }}
                            spin
                          />
                        }
                        className="loader__full"
                      />
                    ) : (
                      campaignSearchData?.length > 0 &&
                      campaignSearchData?.map((item, key) => {
                        if (item?.Event !== "deferred") {
                          return (
                            <tr>
                              <td className="table__status">
                                {item.FirstName}
                              </td>
                              <td>{item.LastName}</td>
                              <td>{item.Email}</td>
                              <td>
                                {item?.Event === "click" && "Clicked"}
                                {item?.Event === "open" && "Opened"}
                                {item?.Event === "delivered" && "Delivered"}
                                {item?.Event === "processed" && "Processed"}
                                {item?.Event === "bounce" && "Bounced"}
                                {item?.Event === "dropped" && "Bounced"}
                              </td>
                              <td>
                                {/* {moment(new Date(new Date(item.DateTime).setHours(new Date(item.DateTime).getHours() - 5))).format(
                                  "MM/DD/yyyy HH:mm A"
                                )} */}
                                {getConvertTimeToEast(item.DateTime)} (ET)
                              </td>
                              <td className="table__status">
                                {item.Url && (
                                  <button
                                    onClick={() => window.open(`${item.Url}`)}
                                    className="text-btn"
                                    style={{ maxWidth: 220 }}
                                  >
                                    {item.Url}
                                  </button>
                                )}
                                {!item.Url && "-"}
                              </td>
                            </tr>
                          );
                        }
                      })
                    )}
                  </tbody>
                </table>
                {campaignSearchData?.length === 0 && !campaignSearchLoader && (
                  <div className="table__blank">
                    <div className="table__blank--text">No Data</div>
                  </div>
                )}
                {campaignSearchData?.length > 0 && (
                  <div className="table__pagination">
                    <div className="table__pagination--results">
                      {size(campaignSearchData)} results
                    </div>
                    <div className="table__pagination--nav">
                      <>
                        {pageSearchNumber - 1 >= 1 ? (
                          <button
                            type="button"
                            className="btn btn__default table__pagination--prev"
                            onClick={() => pageChange("previous")}
                          >
                            Previous
                          </button>
                        ) : (
                          <button
                            type="button"
                            disabled
                            className="btn btn__default table__pagination--prev disabled"
                          >
                            Previous
                          </button>
                        )}
                      </>
                      <>
                        {nextStep - pageSearchNumber > 0 ? (
                          <button
                            type="button"
                            className="btn btn__default table__pagination--next"
                            onClick={() => pageChange("next")}
                          >
                            Next
                          </button>
                        ) : (
                          <button
                            type="button"
                            disabled
                            className="btn btn__default table__pagination--next disabled"
                          >
                            Next
                          </button>
                        )}
                      </>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </main>
      <Drawer
        title={campaignDetails?.CampaignName?.replaceAll("PlainTextTest--FreeFormTest","")
          .replaceAll("PlainTextLive--FreeFormLive","")
          .replaceAll("--test", "")
          .replaceAll("--live", "")
          .replaceAll("--Live", "")
          .replaceAll("--Test", "")
          .replaceAll("-live", "")
          .replaceAll("FreeFormTest", "")
          .replaceAll("FreeFormLive", "")
          .replaceAll("---", "")
          .replaceAll("PlainTextLive", "")
          .replaceAll("PlainTextTest","")
          .replaceAll("-", "")}
        placement={placement}
        width={500}
        onClose={() => onClose()}
        open={open}
      >
        <div className="custom__container">
          <div className="table-responsive table__responsive--custom word__table_break">
            <table className="table table__custom">
              <thead></thead>
              <tbody>
                {campaignUrlDataLoader ? (
                  <Spin
                    size="large"
                    indicator={
                      <LoadingOutlined
                        style={{ fontSize: 30, color: "#000" }}
                        spin
                      />
                    }
                    className="loader__full"
                  />
                ) : (
                  campaignUrlData?.length > 0 &&
                  campaignUrlData?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="table__status">
                          {item.FirstName} {item.LastName}
                        </td>
                        <td className="table__status">{item.Email}</td>
                        <td className="table__status">
                          {/* {moment(
                            new Date(
                              new Date(item.DateTime).setHours(
                                new Date(item.DateTime).getHours() - 5
                              )
                            )
                          ).format("MM/DD/yyyy HH:mm A")}{" "}
                          (ET) */}
                          {getConvertTimeToEast(item.DateTime)} (ET)
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
            {campaignUrlData?.length === 0 && !campaignUrlDataLoader && (
              <div className="table__blank">
                <div className="table__blank--text">No Data</div>
              </div>
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
}
