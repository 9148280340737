import { connect } from "react-redux";
import {
  getNews,
  getLtWeeklyTheme,
  getLtWeeklyThemeCategories,
  getMe,
  updateMailingList,
  updateTags,
  getEvents,
  getAppUsers,
  getLtWeeklyItems,
  getCuratedTags,
  getCategoriesByDate,
  sendMailOtherNews,
  getMailingList,
  getAllCampaigns,
  removeMailingList,
  updateMailingListName,
  getTags,
  getUserDetails,
  AddLTWeeklyAuto,
  ExportLtWeeklyThemes,
  getMailingListById,
  ValidateToken,
  updateTagsName,
  getAllHeaderTags,
  CreateTagsName,
  UpdateNewsCategoryTagsVisibility,
  removeThemeandOtherNewsTagById,
  updateTopTheme
} from "../../store/home/duck";
import AdminComponent from "./component";
// import { ValidateToken } from "../../store/home/api";

const AdminComponentContainer = connect(
  // Map state to props
  (state) => ({}),
  // Map actions to dispatch and props
  {
    getTags,
    updateMailingListName,
    getAllCampaigns,
    getLtWeeklyItems,
    removeMailingList,
    updateMailingList,
    getMailingList,
    updateTags,
    getLtWeeklyThemeCategories,
    getLtWeeklyTheme,
    sendMailOtherNews,
    getCategoriesByDate,
    getCuratedTags,
    getNews,
    getAppUsers,
    getEvents,
    getMe,
    getUserDetails,
    AddLTWeeklyAuto,
    ExportLtWeeklyThemes,
    getMailingListById,
    ValidateToken,
    updateTagsName,
    getAllHeaderTags,
    CreateTagsName,
    UpdateNewsCategoryTagsVisibility,
    removeThemeandOtherNewsTagById,
    updateTopTheme
  }
)(AdminComponent);

export default AdminComponentContainer;
