import React, { useState, useEffect } from "react";
import { get } from "lodash";
import { notification, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Close } from "./../../components/svgIcons";
import { useNavigate } from "react-router-dom";
// import Styles from "./account.module.css"
import PhoneInput, {
  getCountryCallingCode,
  isValidPhoneNumber,
  parsePhoneNumber
} from "react-phone-number-input";
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);
export default function AddAccountComponent(props) {
  const [data, setData] = useState({"FirstName": "", "lastName": "",'AplicationId': 0, "SSOEmail": "", "AlternateEmail": "", "PhoneNumber": "", "IIMWeeklies": 0, "CapitalWeeklies": 0 });
  const [emailError, setEmailError] = useState("")
  const [phoneError, setPhoneError] = useState("")
  const [countryCode, setCountryCode] = useState("US");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstNameError, setFirstNameError] = useState("")
  const [lastNameError, setLastNameError] = useState("")
  const [isLoading, setIsloading] = useState(false);
  const [isCalled, setIsCalled] = useState(false);
  const [alternateemailError, setAlternateemailError] = useState("");
  
  const extractCountryCode = (countryCode) => {
    try {
      return "+" + getCountryCallingCode(countryCode);
    } catch (err) {}
    return "+";
  };
  const extractPhone = (countryCode = "", phoneNumber = "") => {
    return phoneNumber.replace(countryCode, "");
  };
  const handleChange = (name, value) => {
    data[name] = value;
    setData({ ...data });
  };

   // validate phone number
  function phoneValidation() {
    var countryDialCode = extractCountryCode(countryCode);
    var phoneNumberExtract = "";
    if (phoneNumber !== undefined) {
      phoneNumberExtract = extractPhone(countryDialCode, phoneNumber);
    }
    var obj = {};
    if (phoneNumberExtract === "") {
      obj.status = false;
    } else {
      obj.status = true;
    }
    obj.phone = phoneNumberExtract;
    obj.cc = countryDialCode;
    return obj;
  }

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      key: "updatable",
      message: "Liontree",
      description: message,
    });
  };


  useEffect(() => {
    if (!isCalled) {
      setIsCalled(true);
    }
  }, []);

  const validateEmail = (email) => {
    // regular expression to validate email
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email)
  }

  const updateUser = async () => {
    let phoneData = phoneValidation();
    if (!get(data, "FirstName", "")) {
      setFirstNameError("PLease enter FirstName")
    } else if (!get(data, "lastName", "")) {
      setLastNameError("PLease enter lastName")
    }
    // else if (!get(data, "SSOEmail", "")) {
    //   setEmailError("Please enter email")
    // } 
    else if(get(data, "SSOEmail", "") !== "" && !validateEmail(get(data, "SSOEmail", ""))){
      setEmailError("Please enter valid email.")
    }
    else if(get(data, "AlternateEmail", "") !== ""&&!validateEmail(get(data, "AlternateEmail", ""))){
        setAlternateemailError("Please enter valid email.")
    } else if (phoneData?.phone === "") {
      setPhoneError("PLease enter phone number")
    } else if (!isValidPhoneNumber(`${phoneData.cc+phoneData.phone}`) ){
      setPhoneError("PLease enter valid number")
    }
     else {
      setIsloading(true);
      data.PhoneNumber = phoneData.cc + phoneData.phone
      if(data?.IIMWeeklies === null){
        data.IIMWeeklies = 0
      }
      if(data?.CapitalWeeklies === null){
        data.CapitalWeeklies = 0
      }
      let { value } = await props.addProfile(data);
      if (value.Success) {
        setIsloading(false);
        openNotificationWithIcon("success", value.Message);
        navigate('/administration/users')
      }else{
        setIsloading(false);
        openNotificationWithIcon("error", value.Message);
      }
    }
  };
  const navigate = useNavigate();
  return (
    <main className="main" role="tabs">
        <div className="main__header">
          <header className="head">
            <div className="head__container">
              <div className="head__row">
                <div className="head__left">
                  <div className="head__close">
                    <button
                      onClick={() => navigate("/administration/users")}
                      className="btn btn__close"
                    >
                      <Close />
                    </button>
                  </div>
                  <div className="head__divider"></div>
                  <div className="head__title">User Information</div>
                </div>
              </div>
            </div>
          </header>
        </div>
        <div className="custom__container">
          <div className="custom__wrap">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label-primary">
                    First Name<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    name="FirstName"
                    onChange={(e) => {
                      setFirstNameError("")
                      handleChange(e.target.name, e.target.value)
                    }}
                    value={data?.FirstName}
                    className="form-control"
                    required
                  />
                {firstNameError !== "" && (
                    <div className="invalid-feedback">{firstNameError}</div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label-primary">
                    Last Name<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    onChange={(e) => {
                      setLastNameError("")
                      handleChange(e.target.name, e.target.value)
                    }}
                    value={data?.lastName}
                    className="form-control"
                    required
                  />
                  {lastNameError !== "" && (
                    <div className="invalid-feedback">{lastNameError}</div>
                  )}{" "}
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="label-primary">
              SSO Email
              {/* <span className="required">*</span> */}
              </label>
              <input
                type="text"
                value={data?.SSOEmail}
                onChange={(e) => {
                  setEmailError("")
                  handleChange(e.target.name, e.target.value)
                }}
                name="SSOEmail"
                className="form-control"
                required
              />
              {emailError !== "" && (
                <div className="invalid-feedback">{emailError}</div>
              )}{" "}
            </div>
            <div className="form-group">
              <label className="label-primary">
                Alternative Email
              </label>
              <input
                type="text"
                value={data?.AlternateEmail}
                onChange={(e) => {
                  setAlternateemailError("")
                  handleChange(e.target.name, e.target.value)
                }}
                name="AlternateEmail"
                className="form-control"
                required
              />
              {alternateemailError !== "" && (
                <div className="invalid-feedback">{alternateemailError}</div>
              )}{" "}
            </div>
            <div className="form-group">
                <label for="exampleFormControlInput1" class="label-primary">
                  Phone Number
                </label>
                <PhoneInput
                  // ref={phone_input}
                  inputProps={{
                    readOnly: false,
                    name: "phone",
                    required: true,
                    autoFocus: true,
                  }}
                  className="international-phone"
                  defaultCountry="US"
                  country={"US"}
                  value={phoneNumber}
                  onChange={(val) => {
                    setPhoneNumber(val);
                    setPhoneError("")
                  }}
                  onCountryChange={(cc) => setCountryCode(cc)}
                  limitMaxLength="10"
                />
                {phoneError !== "" && (
                  <div className="invalid-feedback">{phoneError}</div>
                )}
            </div>
            <div> Access</div>
      <div className="form-group">
        <ul className="form-list-item removeBorder">
          <li>
            <span>IIM Weeklies Tab</span>
            <label className="custom__checkbox--only">
              <input
                type="checkbox"
                checked={data?.IIMWeeklies}
                name="IIMWeeklies"
                onChange = {()=>handleChange("IIMWeeklies", !data?.IIMWeeklies)}
                className="custom__checkbox--only-input"
              />
              <span className="custom__checkbox--only-check"></span>
            </label>
          </li>
          <li>
            <span>Capital Markets Tab</span>
            <label className="custom__checkbox--only">
              <input
                type="checkbox"
                name="CapitalWeeklies"
                checked={data?.CapitalWeeklies}
                onChange = {()=>handleChange("CapitalWeeklies",!data?.CapitalWeeklies)}
                className="custom__checkbox--only-input"
              />
              <span className="custom__checkbox--only-check"></span>
            </label>
          </li>
        </ul>
      </div> 
            <button
              type="submit"
              className="btn btn-lg btn__purple w-100"
              onClick={() => updateUser()}
            >
              {isLoading ? <Spin indicator={antIcon} /> : "Save"}
            </button>
          </div>
      </div>
    </main>
  );
}
