import axios from "axios";
import get from "lodash/get";

// return promise with data for apply request
const request = (option) => {
  return new Promise(async (resolve) => {
    try {
      // call api and get data and resolve
      const data = await axios(option);
      return resolve(get(data, "data", {}));
    } catch (error) {
      resolve(error);
    }
  });
};
// make default cancel token false
export default (options, cancelToken = false) => {
  const jwtToken = localStorage.getItem("Authorization");
  if (jwtToken || true) {
    let authAddedOptions = options;
    if (typeof options !== "object") {
      authAddedOptions = {};
    }
    if (typeof authAddedOptions.headers !== "object") {
      authAddedOptions.headers = {};
    }
    // set header and update authorization
    authAddedOptions.headers = {
      ...authAddedOptions.headers,
        Authorization: "Bearer " + jwtToken,
      // Authorization:
      //   "Bearer " +
      //   "ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAidW5pcXVlX25hbWUiOiAic2phaW4iLA0KICAiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9BcHBVc2VySWQiOiAiMTE3OTUiLA0KICAiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9GZWRlcmF0ZWRVc2VySWQiOiAiYjk2NzkzMDgtM2IyNC00M2JkLWJhZmYtNWRjMGZiNDUxMGU1IiwNCiAgImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvTmlja05hbWUiOiAiYjk2NzkzMDgtM2IyNC00M2JkLWJhZmYtNWRjMGZiNDUxMGU1IiwNCiAgIm5iZiI6IDE2NjUxMTkyNTIsDQogICJleHAiOiAxNjY3NzExMjUyLA0KICAiaWF0IjogMTY2NTExOTI1MiwNCiAgImlzcyI6ICJpc3MiDQp9.iqai9KxmQ53v3QNXhRFYYGupBycOh8qD2l5b6eT6Xj4",
    };
    console.log(authAddedOptions.cancelToken,'authAddedOptions.cancelToken')
    // check
    if (cancelToken) {
      // add cancel request token append with axios request
      authAddedOptions.cancelToken = cancelToken;
    }
    // call request and update data
    return request(authAddedOptions);
  } else {
    return request(options);
  }
};
