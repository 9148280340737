import React, { useEffect, useCallback, useRef, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { Close } from "../../components/svgIcons";
import { Divider, Spin, notification, Tooltip, Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { isEmpty, set, sortBy, update } from "lodash";
import ReactModal from "react-modal";
import Select from "react-select";
import { _ } from "lodash";
import makeAnimated from "react-select/animated";
import CKEditor from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

const text = <span>prompt text</span>;
const buttonWidth = 70;

// End

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

const HOSTNAME = process.env.REACT_APP_API_HOSTNAME;
const jwtToken = localStorage.getItem("Authorization");
export default function AdminComponent(props) {
  const navigate = useNavigate();

  const location = useLocation();
  const [agoloNewsData, setAgoloNewsData] = useState([]);
  const [ltWeeklyItemData, setLtWeeklyItemData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [curatedList, setCuratedList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCalled, setIsCallApi] = useState(false);
  const [date, setDate] = useState(false);
  const [introductionError, setIntroductionError] = useState("");
  const state = location?.state;
  let { ltWeeklyDate } = useParams();
  const [deleteNewsModal, setDeleteNewsModal] = useState(false);
  const [deletedAgoloNews, setDeletedAgoloNews] = useState({});
  const [tags, setTags] = useState([]);
  const animatedComponents = makeAnimated();
  const [isLtWeeklyData, setIsLtWeeklyData] = useState(false);
  const [searchTags, setSearchTags] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [oldStatus, setOldStatus] = useState("");
  const [sourceToggle, setSourceToggle] = useState(false);
  const [titleToggle, setTitleToggle] = useState(false);
  const [connection, setConnection] = useState(null);
  const [chat, setChat] = useState([]);
  const latestChat = useRef(null);
  const [connectionIds, setConnectionIds] = useState([]);
  const [userData, setUserData] = useState({});
  const [userConnectionList, setUserConnectionList] = useState([]);
  const [firstTimeRecords, setFirstTimeRecords] = useState([]);
  const [isChangedData, setIsChangedData] = useState(false);
  const [groupDates, setGroupDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState("All");
  const intialSaveLoader = Array(agoloNewsData.length).fill(false);
  
  const [saveLoader, setSaveLoader] = useState(intialSaveLoader);
  latestChat.current = chat;
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 1000);
    };
  };

  const setData = (data) => {
    setAgoloNewsData([...data]);
  };

  const optimizedUserFn = useCallback(debounce(setData), []);

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl("https://localhost:44390/hubs/chat")
      //.withUrl('https://lnt-webapp-uat-001.azurewebsites.net/hubs/chat')
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
  }, []);

  useEffect(() => {
    if (connection) {
      const startConnection = async () => {
        try {
          await connection.start();
          // setConnectionIds((prevIds) => [...prevIds, connection.connectionId]);
          // setTimeout(async()=>{
          //   await callUserApi()
          // },2000)
          connection.onclose(() => {
            setConnectionIds((userConnectionList) =>
              userConnectionList.filter(
                (data) => data.id !== userData.AplicationId.toString()
              )
            );
            let userList = userConnectionList.filter(
              (data) => data.id !== userData.AplicationId.toString()
            );

            connection.on("ConnectedUserList", (userList) => {});
          });
        } catch (e) {
          console.log("Connection failed: ", e);
        }
      };
      connection.on("RecieveConnections", async (userlist) => {
        try {
          let list = [...userlist];

          setFirstTimeRecords([...list]);
          await callUserApi();
        } catch (error) {
          console.log("eeeee", error);
        }
      });

      connection.on("disconnect", (data) => {});
      startConnection();

      connection.on("ReceiveMessage", (NewsData) => {
        // Find the record in the state array that matches the received data by some unique identifier (e.g., id)
        const recordToUpdate = agoloNewsData.find(
          (d) => d.Id === NewsData[0].id
        );
        if (recordToUpdate) {
          const recordToUpdateIndex = agoloNewsData.findIndex(
            (d) => d.Id === NewsData[0].id
          );
          // if(NewsData[0]?.searchTag){
          //   searchTags[recordToUpdateIndex] = NewsData[0].searchTag;
          //   setSearchTags([...searchTags]);
          // }else if(NewsData[0]?.searchTag === ''){
          //   searchTags[recordToUpdateIndex] = NewsData[0].searchTag;
          //   setSearchTags([...searchTags]);
          // }
          // Create a new array with the updated data
          // agoloNewsData.map((d) =>{
          //   if(d.Description !== NewsData[0].description &&  d.Id === NewsData[0].id){
          //     setIsChangedData(true)
          //   }
          // });
          const updatedAgoloNewsData = agoloNewsData.map((d) =>
            d.Id === NewsData[0].id
              ? {
                  ...d,
                  Description: NewsData[0].description,
                  Category: NewsData[0].category,
                  Curated: NewsData[0].curated,
                  SecondaryCategory: NewsData[0].secondaryCategory,
                  Source: NewsData[0].source,
                  Status: NewsData[0].status,
                  Title: NewsData[0].title,
                }
              : d
          );

          optimizedUserFn(updatedAgoloNewsData);
        }
      });

      connection.on("DeleteReceiveAgoloNews", (Id) => {
        agoloNewsData.splice(
          agoloNewsData.findIndex((data) => data.Id === Id),
          1
        );
        setAgoloNewsData([...agoloNewsData]);
      });
      connection.on("RecieveJoinUsers", (list) => {

        let userList = [...userConnectionList];
        let index = userList?.findIndex((u) => list[0].id === u.id);
        if (index === -1) {
          userList.push(list[0]);
        }
        if (userList?.length > 0) {
          setUserConnectionList([...userList]);
          // setUserConnectionList((prevList)=>[...prevList, ...userList])
        }
      });
      connection.on("RecieveUpdatePlaceIndex", async (list) => {
        let userList = [...userConnectionList];
        let newArry = [];
        let prm = userList?.map((u) => {
          if (newArry.findIndex((d) => d.id === u.id) === -1) {
            newArry.push(u);
          } else {
            let d = newArry.find((d) => d.id === u.id);
            if (d.id === u.id && d.index === -1 && u.index !== -1) {
              //newArry.splice(_.indexOf(newArry, _.findWhere(newArry, { id : u.id})), 1);
              const indexToRemove = newArry.findIndex(
                (item) => item.id === u.id
              );
              if (indexToRemove !== -1) {
                newArry.splice(indexToRemove, 1);
              }
              newArry.push(u);
            }
          }
        });
        await Promise.all(prm);
        userList = newArry;
        let data = userList?.find((u) => list[0].id === u.id);
        if (!data) {
          userList.push(list[0]);
          setUserConnectionList([...userList]);
        } else {
          data.index = list[0]?.index;
          if (userList?.length > 0) {
            const indexToRemove = newArry.findIndex(
              (item) => item.id === list[0].id
            );
            if (indexToRemove !== -1) {
              newArry.splice(indexToRemove, 1);
            }
            // userList.splice(_.indexOf(userList, _.findWhere(userList, { id : list[0].id})), 1);
          }
          userList.push(data);
          // userList[index]['Index'] = list[0].index
          setUserConnectionList([...userList]);
        }
      });
      connection.on("UserDisconnected", (SocketId) => {
        let userList = [...userConnectionList];
        // userList.splice(_.indexOf(userList, _.findWhere(userList, { socketId : SocketId})), 1);
        const filteredArray = userList.filter(
          (item) => item.socketId !== SocketId
        );

        setUserConnectionList([...filteredArray]);

        // Handle the user disconnection here, e.g., remove the user from the screen or show a notification.
      });
    }
  }, [
    connection,
    agoloNewsData,
    userConnectionList,
    userData,
    firstTimeRecords,
  ]);
  // useEffect(() => {
  //   console.log(userConnectionList, 'this is updated userlist');
  //   // You can call your API function here, it will be triggered after the state is updated.
  //   // await callUserApi()
  // }, [userConnectionList]);
  function getRandomColor() {
    var chars = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      var index = Math.floor(Math.random() * 16);
      color += chars[index];
    }
    return color;
  }

  function getUniqueColor() {
    var color = getRandomColor();
    if (userConnectionList?.findIndex((c) => c.color === color) !== -1) {
      color = getRandomColor();
    }
    return color;
  }

  const callGetUserDetails = async (id) => {
    const { value } = await props.getUserDetails(parseInt(id));
    if (connection && connection.state === "Connected") {
      let color = await getUniqueColor();
      let name = value.FirstName;
      // setTimeout(()=>{
      // connection.on("RecieveUserConnectionList", (userlist)=>{
      //   console.log(userlist,"userlistData")
      //   setUserConnectionList([...userlist])
      // })

      let list = [...userConnectionList];
      let prm = firstTimeRecords?.map((u) => {
        list.push(u);
      });
      await Promise.all(prm);
      list.push({
        userName: name,
        index: -1,
        id: value.AplicationId.toString(),
        color: color,
        socketId: connection.connectionId,
      });
      const updatedList = [
        ...firstTimeRecords,
        {
          userName: name,
          index: -1,
          id: value.AplicationId.toString(),
          color: color,
          socketId: connection.connectionId,
        },
      ];
      setUserConnectionList((prevList) => [...prevList, ...updatedList]);
      // setUserConnectionList([updatedList]);

      // },1000)
      connection.send("JoinUser", [
        {
          UserName: name,
          Index: -1,
          Id: value.AplicationId.toString(),
          color: color,
          SocketId: connection.connectionId,
        },
      ]);
    }
    setUserData({ ...value });
  };

  useEffect(() => {
    const startCall = async () => {
      await callUserApi();
    };
    if (connection && connection.state === "Connected") {
      startCall();
    }
  }, [connection?.state]);

  const callUserApi = async () => {
    const { value } = await props.getMe();
    if (value) {
      await callGetUserDetails(value?.Id);
    }
  };

  const closeNewsModal = () => {
    setDeletedAgoloNews({});
    setDeleteNewsModal(false);
  };

  const callCuratedTagListAPI = async () => {
    const { value } = await props.getCuratedTags();
    if (value) {
      let list = [];
      let prm = value?.Result?.map((r) => {
        let v = {
          value: r.Id,
          label: r.Name,
          color: "#00B8D9",
          isFixed: false,
        };
        list.push(v);
      });
      await Promise.all(prm);
      setCuratedList([...list]);
    }
  };
  const callApi = async (d, status) => {
    if (status === "" && selectedAgoloNewsStatus === "") {
      status = "All";
    }
    if (status || selectedAgoloNewsStatus) {
      setLoader(true);
      // localStorage.setItem("articleStatus", status);
      //d = d?.replace("Week ending ", "");
      let endDate = new Date(d);
      setAgoloNewsData([]);
      let startDate = new Date(d);
      var lastSaturday = new Date(
        new Date(d).setDate(
          new Date(d).getDate() -
            (new Date(d).getDay() == 0 ? 7 : new Date(d).getDay() + 1)
        )
      );
      endDate = moment(endDate, "MM DD YYYY").format("YYYY-MM-DD");
      // startDate = startDate.setDate(startDate.getDate() - 6);
      startDate = moment(lastSaturday, "MM DD YYYY").format("YYYY-MM-DD");
      let lastDate = new Date(lastSaturday.setDate(lastSaturday.getDate() + 6));
      endDate = moment(lastDate, "MM DD YYYY").format("YYYY-MM-DD");
      let thisWeekSaturdayDate = endDate;
      let currentClickDate = moment(new Date(d), "MM DD YYYY").format(
        "YYYY-MM-DD"
      );
      let data = {
        startDate: startDate + " 00:00:00",
        endDate: endDate + " 23:59:00",
        // isThisWeek: currentClickDate === thisWeekSaturdayDate ? true : false,
        isThisWeek: true,
        // status: "All",
        status: !status ? selectedAgoloNewsStatus : status,
      };
      if (data.status === oldStatus && !selectedDate) {
        setLoader(true);
        data.status = "All";
      }
      localStorage.setItem("articleStatus", data.status);
      setOldStatus(data.status);

      const { value } = await props.getAgoloNewsByDate(data);
      if (value) {
        let news = value?.Data.reverse();
        setAgoloNewsData([...news]);
        let l = setInterval(() => {
          setLoader(false);
          setIsLtWeeklyData(true);
          clearInterval(l);
        }, 1000);
      }
    }
  };

  const handleDateSelection = async (selectedOption) => {
    const status = selectedAgoloNewsStatus;
    setSelectedDate(selectedOption.value); // Update selected date
    // await props.getAgoloNewsByDate(selectedOption.value); // Call your news API with the selected date
    getNewsBySelectedDate(selectedOption.value, status);
  };

  const getNewsBySelectedDate = async (selectedDate, status) => {
    try {
      let d = ltWeeklyDate;
      if (status == "") {
        status = "All";
      }
      if (selectedDate === "All") {
        if (selectedAgoloNewsStatus === "") {
          callApi(d, "All");
          setSelectedAgoloNewsStatus("All");
        } else {
          callApi(d, selectedAgoloNewsStatus);
        }
        // setLoader(true);
      } else {
        setLoader(true);
        const formattedStartDate = moment(selectedDate).format(
          "YYYY-MM-DD 00:00:00"
        );
        const formattedEndDate = moment(selectedDate).format(
          "YYYY-MM-DD 23:59:59"
        );
        const data = {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          isThisWeek: true,
          status: !status
            ? !selectedAgoloNewsStatus
              ? "All"
              : selectedAgoloNewsStatus
            : status,
        };
        const { value } = await props.getAgoloNewsByDate(data);
        if (value) {
          let news = value?.Data.reverse();
          setAgoloNewsData([...news]);
          setLoader(false);
          let l = setInterval(() => {
            setLoader(false);
            setIsLtWeeklyData(true);
            clearInterval(l);
          }, 1000);
        }
      }
    } catch (error) {
      console.error("Error fetching news by selected date:", error);
    }
  };

  const fetchGroupDates = async () => {
    try {
      const date = ltWeeklyDate;

      const endDate = moment(date, "MMMM-DD-YYYY").format("YYYY-MM-DD");

      // Calculate the start date by subtracting 6 days from the end date
      const startDate = moment(endDate, "YYYY-MM-DD")
        .subtract(6, "days")
        .format("YYYY-MM-DD");

      // console.log(startDate, "startDate===>", endDate, "endDate===>");

      const formattedStartDate = startDate + " 00:00:00";
      const formattedEndDate = endDate + " 23:59:59";

      // Construct the value object for the API call
      const value = {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      };

      const dates = await props.getAllCreatedAgoloNewsDate(value);

      // Convert dates to the desired format for the Select component
      const formattedDates =
        dates?.value?.Result?.map((item) => ({
          value: item.GroupDate,
          label: moment(item.GroupDate).format("MMM DD, YYYY"), // Format the date as you need
        })) || [];
      setGroupDates(formattedDates);
    } catch (error) {
      console.error("Error fetching group dates:", error);
    }
  };
  useEffect(() => {
    fetchGroupDates();
  }, []);

  const callApiGetLtWeeklyItems = async (date) => {
    date = new Date(date);
    date = moment(date, "MM DD YYYY").format("MM-DD-YYYY");
    const { value } = await props.getLtWeeklyItems({ date });
    if (value?.Success) {
      if (!isEmpty(value?.Result?.Data)) {
        setLtWeeklyItemData(value?.Result?.Data);
      }
    }
  };

  useEffect(() => {
    if (!isCalled) {
      // callUserApi()
      let status = "";
      if (!localStorage.getItem("articleStatus")) {
        status = "Completed|Pending|Saved";
        handleStatus([
          StatusOptionsForAll[1],
          StatusOptionsForAll[3],
          StatusOptionsForAll[2],
        ]);
      } else if (
        localStorage.getItem("articleStatus") === "All" ||
        localStorage.getItem("articleStatus") === "all"
      ) {
        status = "Completed|Pending|Saved";
        handleStatus([StatusOptionsForAll[0]]);
      } else if (
        localStorage.getItem("articleStatus").includes("Saved") &&
        localStorage.getItem("articleStatus").includes("Pending") &&
        localStorage.getItem("articleStatus").includes("Completed")
      ) {
        status = "Saved|Pending|Completed";
        handleStatus([
          StatusOptionsForAll[1],
          StatusOptionsForAll[2],
          StatusOptionsForAll[3],
        ]);
      } else if (
        localStorage.getItem("articleStatus").includes("Saved") &&
        localStorage.getItem("articleStatus").includes("Completed")
      ) {
        status = "Saved|Completed";
        handleStatus([StatusOptionsForAll[2], StatusOptionsForAll[3]]);
      } else if (
        localStorage.getItem("articleStatus").includes("Pending") &&
        localStorage.getItem("articleStatus").includes("Completed")
      ) {
        status = "Pending|Completed";
        handleStatus([StatusOptionsForAll[1], StatusOptionsForAll[3]]);
      } else if (
        localStorage.getItem("articleStatus").includes("Saved") &&
        localStorage.getItem("articleStatus").includes("Pending")
      ) {
        status = "Pending|Completed";
        handleStatus([StatusOptionsForAll[1], StatusOptionsForAll[2]]);
      } else if (localStorage.getItem("articleStatus") === "Completed") {
        status = "Completed";
        handleStatus([StatusOptionsForAll[3]]);
      } else if (localStorage.getItem("articleStatus") === "Pending") {
        status = "Pending";
        handleStatus([StatusOptionsForAll[1]]);
      }
      if (localStorage.getItem("articleStatus") === "Saved") {
        status = "Saved";
        handleStatus([StatusOptionsForAll[2]]);
      }
      // localStorage.setItem("articleStatus",status)
      getLtWeeklyThemeCategoriesApi();
      setDate(ltWeeklyDate);
      callCuratedTagListAPI();
      callApiGetLtWeeklyItems(ltWeeklyDate);
      callApi(ltWeeklyDate, status);
      setIsCallApi(true);
      getModifyCuratedList();
    }
  }, [
    agoloNewsData,
    curatedList,
    tags,
    agoloNewsData,
    curatedList,
    ltWeeklyItemData,
  ]);

  const custom_config = {
    // plugins: ['Undo'],
    toolbar: {
      // items: ['autoSave'],
      // autoSave: true,
      htmlEmbed: true,
      shouldNotGroupWhenFull: true,
      mediaEmbed: false,
      table: false,
    },
    removePlugins: [
      "Alignment",
      "Autoformat",
      "BlockQuote",
      "CKBox",
      "CloudServices",
      // "Essentials",
      "FontBackgroundColor",
      "FontColor",
      "FontFamily",
      "FontSize",
      "Heading",
      "Image",
      "ImageCaption",
      "ImageInsert",
      "ImageResize",
      "ImageStyle",
      "ImageToolbar",
      "ImageUpload",
      "Indent",
      "Italic",
      "Link",
      "List",
      "MediaEmbed",
      //   "Paragraph",
      // "PasteFromOffice",
      "PictureEditing",
      "SourceEditing",
      "Strikethrough",
      "Table",
      "TableCaption",
      "TableCellProperties",
      "TableColumnResize",
      //  "TableProperties",
      // "TableToolbar",
      // "TextTransformation",
      "Underline",
    ],
  };

  const addCuratedList = async (e, data, i) => {
    let curated = [];
    let l = e?.map((e) => {
      curated.push(e.value?.toString());
    });
    await Promise.all(l);
    curated = curated.join("|");
    agoloNewsData[i].Curated = curated;
    let ind = userConnectionList?.findIndex(
      (conUser) =>
        conUser?.Id === userData.AplicationId.toString() ||
        conUser?.id === userData.AplicationId.toString()
    );
    let record = userConnectionList[ind];
    if (record !== undefined && record !== null) {
      if (record?.Index) {
        record.Index = agoloNewsData[i].Id;
      } else {
        record.index = agoloNewsData[i].Id;
      }
      await connection.send("UpdatePlaceIndex", [record]);
    }
    await connection.send("SendMessage", [agoloNewsData[i]]);
    let payload = {
      Data: [
        {
          Id: data.Id,
          Curated: curated,
        },
      ],
    };
    const { value } = await props.addCuratedTags(payload);
    if (value?.Success) {
      const state = location?.state;
      // callApi(ltWeeklyDate, selectedAgoloNewsStatus);
    }
  };

  const getModifyCuratedList = (value) => {
    const val = value?.split("|");
    let valList = [];
    let prm = val?.map((e, index) => {
      let isChecked = curatedList.filter((obj) => {
        return obj.value?.toString() === e;
      });
      if (isChecked.length > 0) {
        valList.push(isChecked[0]);
      }
    });
    if (val?.length > 0) Promise.all(prm);
    valList = sortBy(valList, "label");
    return valList;
  };

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      key: "updatable",
      message: "Liontree",
      description: message,
    });
  };

  const handleChangeData = (data, index, key) => {
    return async (event, editor) => {
      // editor.setData(editor.getData())
      if (isLtWeeklyData) {
        setIntroductionError("");
        agoloNewsData[index].Description = editor.getData();
        setAgoloNewsData([...agoloNewsData]);
      }
    };
  };

  const handleEditorBlur = async (index) => {
    if (connection && connection.state === "Connected") {
      agoloNewsData[index].searchTag = searchTags[index];
      await connection.send("SendMessage", [agoloNewsData[index]]);

      let userList = [...userConnectionList];
      let ind = userList?.findIndex(
        (conUser) => conUser?.id === userData?.AplicationId?.toString()
      );
      let record = userConnectionList[ind];
      if (record !== undefined && record !== null) {
        if (record?.Index) {
          record.Index = agoloNewsData[index].Id;
        } else {
          record.index = agoloNewsData[index].Id;
        }
      }
      // console.log(record,'record---?>>')
      // if(isChangedData && record?.index){
      //   await connection.send('UpdatePlaceIndex', [record]);
      // }

      optimizedAgoloNewsFn(agoloNewsData[index]);
    }
  };

  const handleEditorFocus = async (index) => {
    if (connection && connection.state === "Connected") {
      agoloNewsData[index].searchTag = searchTags[index];
      // await connection.send('SendMessage', [agoloNewsData[index]]);

      let userList = [...userConnectionList];
      let ind = userList?.findIndex(
        (conUser) => conUser?.id === userData?.AplicationId?.toString()
      );
      let record = userConnectionList[ind];
      if (record !== undefined && record !== null) {
        if (record?.Index) {
          record.Index = agoloNewsData[index].Id;
        } else {
          record.index = agoloNewsData[index].Id;
        }
      }
      // setIsChangedData(false)
      if (record?.index) {
        await connection.send("UpdatePlaceIndex", [record]);
      }
      // optimizedAgoloNewsFn(agoloNewsData[index])
    }
  };

  const handleDate = (date) => {
    const str = "Edit other news for week ending ";
    date = moment(date).format("ll");
    date = str.concat(date);
    return date;
  };

  const removeOtherNews = async () => {
    setIsLoading(true);
    let payload = {
      articleId: deletedAgoloNews?.Id,
    };
    const { value } = await props.removeAgoloNews({ Id: payload?.articleId });
    if (value.Success) {
      // callApi(ltWeeklyDate);
      if (connection && connection.state === "Connected") {
        await connection?.send("DeleteAgoloNews", deletedAgoloNews.Id);
      }
      agoloNewsData.splice(
        agoloNewsData.findIndex((data) => data.Id === deletedAgoloNews.Id),
        1
      );
      // const filteredNews = await agoloNewsData.filter((item) => item.Id !== payload?.articleId);
      setAgoloNewsData([...agoloNewsData]);
      // setLoader(true)
      closeNewsModal();
      setDeletedAgoloNews({});
      setIsLoading(false);
    } else {
      closeNewsModal();
      setDeletedAgoloNews({});
      setIsLoading(false);
    }
  };

  const handleChange = async (i, val, key) => {
    let singleAgoloData = agoloNewsData[i];
    singleAgoloData[key] = val?.toString();
    agoloNewsData[i] = singleAgoloData;
    setAgoloNewsData([...agoloNewsData]);
    // let ind = userConnectionList?.findIndex((conUser)=> conUser?.Id === userData?.AplicationId?.toString() || conUser?.id === userData?.AplicationId?.toString())
    // let record = userConnectionList[ind]
    // if(record !== undefined && record !== null){
    //   if(record?.Index){
    //     record.Index = agoloNewsData[i].Id
    //   }else{
    //     record.index = agoloNewsData[i].Id
    //   }
    //   await connection.send('UpdatePlaceIndex', [record]);
    // }
    // await connection.send('SendMessage', [agoloNewsData[i]]);
    // optimizedAgoloNewsFn(agoloNewsData[i])
  };
  const handleFocus = async (index) => {
    if (connection && connection.state === "Connected") {
      let ind = userConnectionList?.findIndex(
        (conUser) =>
          conUser?.Id === userData?.AplicationId?.toString() ||
          conUser?.id === userData?.AplicationId?.toString()
      );
      let record = userConnectionList[ind];
      if (record !== undefined && record !== null) {
        if (record?.Index) {
          record.Index = agoloNewsData[index].Id;
        } else {
          record.index = agoloNewsData[index].Id;
        }
      }
      if (record?.index) {
        await connection.send("UpdatePlaceIndex", [record]);
      }
      // optimizedAgoloNewsFn(agoloNewsData[index]);
    }
  };

  const handleBlur = async (index) => {
    if (connection && connection.state === "Connected") {
      agoloNewsData[index].searchTag = searchTags[index];
      await connection.send("SendMessage", [agoloNewsData[index]]);

      let userList = [...userConnectionList];
      let ind = userList?.findIndex(
        (conUser) => conUser?.id === userData?.AplicationId?.toString()
      );
      let record = userConnectionList[ind];
      if (record !== undefined && record !== null) {
        if (record?.Index) {
          record.Index = agoloNewsData[index].Id;
        } else {
          record.index = agoloNewsData[index].Id;
        }
      }
      optimizedAgoloNewsFn(agoloNewsData[index]);
    }
  };

  const StatusOptions = [
    { value: "Pending", label: "Draft" },
    { value: "Saved", label: "Saved" },
    { value: "Completed", label: "Approved" },
  ];

  const getLtWeeklyThemeCategoriesApi = async () => {
    const { value } = await props.getLtWeeklyThemeCategories();
    if (value?.Result?.length > 0) {
      value.Result = sortBy(value?.Result, (e) => e.Name?.toLowerCase());
      setTags(value?.Result);
      // setLoader(false);
    }
  };

  const handlePrimaryTag = async (i, val) => {
    let singleAgoloData = agoloNewsData[i];
    if (singleAgoloData.SecondaryCategory?.includes(val.toString())) {
      singleAgoloData.SecondaryCategory =
        singleAgoloData.SecondaryCategory.replaceAll(`${val.toString()}|`, "").replaceAll(
          `|${val.toString()}`,
          ""
        ).replaceAll(`${val.toString()}`, "");
    }
    singleAgoloData.Category = val?.toString();
    
    
    agoloNewsData[i] = singleAgoloData;
    setAgoloNewsData([...agoloNewsData]);
    let ind = userConnectionList?.findIndex(
      (conUser) =>
        conUser?.Id === userData?.AplicationId?.toString() ||
        conUser?.id === userData?.AplicationId?.toString()
    );
    let record = userConnectionList[ind];

    if (record !== undefined && record !== null) {
      if (record?.Index) {
        record.Index = agoloNewsData[i].Id;
      } else {
        record.index = agoloNewsData[i].Id;
      }
      await connection.send("UpdatePlaceIndex", [record]);
    }
    await connection.send("SendMessage", [agoloNewsData[i]]);
    optimizedAgoloNewsFn(agoloNewsData[i]);
  };

  const handleSocketData = async (an) => {
    const { value } = await props.updateArticles([an]);
  };
  const optimizedAgoloNewsFn = useCallback(debounce(handleSocketData), []);

  const handleSave = async (an, i, val) => {
    let data = saveLoader;
    // data[i] = true
    // setSaveLoader([...data])
    // let temp = agoloNewsData;
    if (an?.Category !== "") {
      setSelectedData([...selectedData, an.Id]);
      an.Status = val;
      const { value } = await props.updateArticles([an]);
      if (value?.Success) {
        // data[i] = false
        // setSaveLoader([...data])
        // openNotificationWithIcon('success', 'Article updated successfully');
        let d = ltWeeklyDate;
        let endDate = new Date(d);
        let startDate = new Date(d);
        var lastSaturday = new Date(
          new Date(d).setDate(
            new Date(d).getDate() -
              (new Date(d).getDay() == 0 ? 7 : new Date(d).getDay() + 1)
          )
        );
        endDate = moment(endDate, "MM DD YYYY").format("YYYY-MM-DD");
        startDate = moment(lastSaturday, "MM DD YYYY").format("YYYY-MM-DD");
        let lastDate = new Date(
          lastSaturday.setDate(lastSaturday.getDate() + 6)
        );
        endDate = moment(lastDate, "MM DD YYYY").format("YYYY-MM-DD");
        let thisWeekSaturdayDate = endDate;
        let currentClickDate = moment(new Date(d), "MM DD YYYY").format(
          "YYYY-MM-DD"
        );
        startDate = startDate + " 00:00:00";
        endDate = endDate + " 23:59:00";
        let sDay = new Date(startDate).getDate();
        let eDay = new Date(endDate).getDate();
        let month = new Date(startDate).getMonth();
        let year = new Date(startDate).getFullYear();

        month = parseInt(month) + 1;

        month = parseInt(month);
        if (month > 0 && month < 10) {
          month = "0" + month;
        }
        month = parseInt(month);

        // for (var i = parseInt(sDay); i <= parseInt(eDay); i++) {
        //   await props.SyncOtherNews({ day: i, year: year, month: month });
        // }
      }
    } else {
      openNotificationWithIcon("error", "Please select Primary Category");
    }
  };

  const handleSecondaryTag = async (i, val) => {
    let singleAgoloData = agoloNewsData[i];
    if (singleAgoloData.Category?.includes(val.toString())) {
      singleAgoloData.Category = singleAgoloData.Category.replaceAll(
        `${val.toString()}`,
        ""
      );
    }
    if (singleAgoloData.SecondaryCategory?.includes(val.toString())) {
      singleAgoloData.SecondaryCategory =
        singleAgoloData.SecondaryCategory.replaceAll(
          `|${val.toString()}`,
          ""
        ).replaceAll(`${val.toString()}`, "");
    } else {
      if (singleAgoloData.SecondaryCategory) {
        singleAgoloData.SecondaryCategory =
          singleAgoloData.SecondaryCategory + "|" + val.toString();
      } else {
        singleAgoloData.SecondaryCategory = val.toString();
      }
    }
    agoloNewsData[i] = singleAgoloData;
    setAgoloNewsData([...agoloNewsData]);
    let ind = userConnectionList?.findIndex(
      (conUser) =>
        conUser?.Id === userData?.AplicationId?.toString() ||
        conUser?.id === userData?.AplicationId?.toString()
    );
    let record = userConnectionList[ind];
    if (record !== undefined && record !== null) {
      if (record?.Index) {
        record.Index = agoloNewsData[i].Id;
      } else {
        record.index = agoloNewsData[i].Id;
      }
      await connection.send("UpdatePlaceIndex", [record]);
    }
    await connection.send("SendMessage", [agoloNewsData[i]]);
    optimizedAgoloNewsFn(agoloNewsData[i]);
  };

  const onChangeStatus = async (value, label, data, i) => {
    let d = agoloNewsData[i];
    d["Status"] = value;
    agoloNewsData[i] = d;
    let ind = userConnectionList?.findIndex(
      (conUser) =>
        conUser?.Id === userData?.AplicationId?.toString() ||
        conUser?.id === userData?.AplicationId?.toString()
    );
    let record = userConnectionList[ind];
    if (record !== undefined && record !== null) {
      if (record?.Index) {
        record.Index = agoloNewsData[i].Id;
      } else {
        record.index = agoloNewsData[i].Id;
      }
      await connection.send("UpdatePlaceIndex", [record]);
    }
    await connection.send("SendMessage", [agoloNewsData[i]]);
    setAgoloNewsData([...agoloNewsData]);
  };

  const getSearchTags = (an, i) => {
    if (searchTags[i] === "") {
      return tags
        ?.sort((a, b) => a.Title.localeCompare(b.Title))
        .map((t, index) => {
          return (
            <li>
              <span>{t?.Title}</span>
              <div className="btn-container-item">
                {/* {an.Category > 0 } */}
                <button
                  name="primary"
                  value={t?.Title}
                  className={
                    t?.Title == an.Category
                      ? "btn btn__purple"
                      : "btn btn__default"
                  }
                  onClick={(e) => handlePrimaryTag(i, e.target.value)}
                >
                  Primary
                </button>
                <button
                  name="secondary"
                  value={t?.Title}
                  className={
                    an.SecondaryCategory?.includes(t?.Title)
                      ? "btn btn__purple"
                      : "btn btn__default"
                  }
                  onClick={(e) => {
                    handleSecondaryTag(i, e.target.value);
                  }}
                >
                  Secondary
                </button>
              </div>
            </li>
          );
        });
    } else if (searchTags[i] !== "") {
      let search = "piz";
      let condition = new RegExp(searchTags[i], "i");
      var result = tags.filter(function (el) {
        return condition.test(el.Name) || condition.test(el.Title);
      });
      return result
        ?.sort((a, b) => a.Title.localeCompare(b.Title))
        .map((t, index) => {
          return (
            <li>
              <span>{t?.Title}</span>
              <div className="btn-container-item">
                {/* {an.Category > 0 } */}
                <button
                  name="primary"
                  value={t?.Title}
                  className={
                    t?.Title == an.Category
                      ? "btn btn__purple"
                      : "btn btn__default"
                  }
                  onClick={(e) => handlePrimaryTag(i, e.target.value)}
                >
                  Primary
                </button>
                <button
                  name="secondary"
                  value={t?.Title}
                  className={
                    an.SecondaryCategory?.includes(t?.Title)
                      ? "btn btn__purple"
                      : "btn btn__default"
                  }
                  onClick={(e) => {
                    handleSecondaryTag(i, e.target.value);
                  }}
                >
                  Secondary
                </button>
              </div>
            </li>
          );
        });
    }
  };

 

  const searchTagText = async (eve, tags, index) => {
    searchTags[index] = eve.target.value;
    setSearchTags([...searchTags]);
    // focus
    // let ind = userConnectionList?.findIndex((conUser)=> conUser?.Id === userData?.AplicationId?.toString() || conUser?.id === userData?.AplicationId?.toString())
    // let record = userConnectionList[ind]
    // if(record !== undefined && record !== null){
    //   if(record?.Index){
    //     record.Index = agoloNewsData[index].Id
    //   }else{
    //     record.index = agoloNewsData[index].Id
    //   }
    //   await connection.send('UpdatePlaceIndex', [record]);
    // }
    // //vblur
    // agoloNewsData[index].searchTag = eve.target.value
    // await connection.send('SendMessage', [agoloNewsData[index]]);
  };

  const handleFocusSearch = async (index) => {
    let ind = userConnectionList?.findIndex(
      (conUser) =>
        conUser?.Id === userData?.AplicationId?.toString() ||
        conUser?.id === userData?.AplicationId?.toString()
    );
    let record = userConnectionList[ind];
    if (record !== undefined && record !== null) {
      if (record?.Index) {
        record.Index = agoloNewsData[index].Id;
      } else {
        record.index = agoloNewsData[index].Id;
      }
      await connection.send("UpdatePlaceIndex", [record]);
    }
  };
  const handleBlurSearch = async (index) => {
    agoloNewsData[index].searchTag = searchTags[index];
    let ind = userConnectionList?.findIndex(
      (conUser) =>
        conUser?.Id === userData?.AplicationId?.toString() ||
        conUser?.id === userData?.AplicationId?.toString()
    );
    let record = userConnectionList[ind];
    if (record !== undefined && record !== null) {
      if (record?.Index) {
        record.Index = agoloNewsData[index].Id;
      } else {
        record.index = agoloNewsData[index].Id;
      }
      // agoloNewsData[index].searchTag = eve.target.value
      await connection.send("SendMessage", [agoloNewsData[index]]);
    }
  };

  const updateOtherNews = async (an, i) => {
    let data = saveLoader;
    data[i] = true;
    setSaveLoader([...data]);
    an = [an];
    // agoloNewsData = [an]
    let notCategoryRecords = an.findIndex((a) => a.Category === "");
    if (notCategoryRecords > -1) {
      openNotificationWithIcon(
        "error",
        `Please select Primary Category Of this title "${an[notCategoryRecords]?.Title}"`
      );
    } else {
      setIsLoading(true);
      await props.AddNewLTWeeklyEntry()
      const { value } = await props.updateArticles(an);
      if (value?.Success) {
        // setLoader(true);
        openNotificationWithIcon("success", "Update article successfully.");
        // callApi(ltWeeklyDate, selectedAgoloNewsStatus);
         setIsLoading(false);
        let d = ltWeeklyDate;
        let endDate = new Date(d);
        let startDate = new Date(d);
        var lastSaturday = new Date(
          new Date(d).setDate(
            new Date(d).getDate() -
              (new Date(d).getDay() == 0 ? 7 : new Date(d).getDay() + 1)
          )
        );
        endDate = moment(endDate, "MM DD YYYY").format("YYYY-MM-DD");
        startDate = moment(lastSaturday, "MM DD YYYY").format("YYYY-MM-DD");
        let lastDate = new Date(
          lastSaturday.setDate(lastSaturday.getDate() + 7)
        );
        endDate = moment(lastDate, "MM DD YYYY").format("YYYY-MM-DD");
        let thisWeekSaturdayDate = endDate;
        let currentClickDate = moment(new Date(d), "MM DD YYYY").format(
          "YYYY-MM-DD"
        );
        startDate = startDate + " 00:00:00";
        endDate = endDate + " 23:59:00";

        await props.UpdateLTWeeklyOthersNews({
          // news: agoloNewsData,
          news: an,
          startDate: startDate,
          endDate: endDate,
          itemName: ltWeeklyItemData.ItemName,
          ltWeeklyId: ltWeeklyItemData.Id,
        });
        let sDay = new Date(startDate).getDate();
        let eDay = new Date(endDate).getDate();
        let month = new Date(startDate).getMonth();
        let year = new Date(startDate).getFullYear();
        data[i] = false;
        setSaveLoader([...data]);
        month = parseInt(month) + 1;

        month = parseInt(month);
        if (month > 0 && month < 10) {
          month = "0" + month;
        }
        month = parseInt(month);

        // for (var i = parseInt(sDay); i <= parseInt(eDay); i++) {
        //   await props.SyncOtherNews({ day: i, year: year, month: month });
        // }
        // console.log(value)
      }
    }
  };

  const [isAllStatusDisabled, setIsAllStatusDisabled] = useState(true);
  const [isStatusDisabled, setIsStatusDisabled] = useState(false);
  const [
    selectedAgoloNewsStatusWithComma,
    setSelectedAgoloNewsStatusWithComma,
  ] = useState([
    { value: "Pending", label: "Draft", isDisabled: isStatusDisabled },
    { value: "Completed", label: "Approved", isDisabled: isStatusDisabled },
    { value: "Saved", label: "Saved", isDisabled: isStatusDisabled },
  ]);
  const [selectedAgoloNewsStatus, setSelectedAgoloNewsStatus] = useState("");

  const handleStatus = (status) => {
    let findAll = status?.findIndex((s) => s?.value !== "All");
    if (findAll !== -1) {
      setIsAllStatusDisabled(true);
    }
    if (status.length == 0) {
      setIsAllStatusDisabled(false);
      // setSelectedAgoloNewsStatus("All")
      setIsStatusDisabled(false);
    } else {
      setLoader(true);
      status?.map((item) => {
        return item.value === "All"
          ? setIsStatusDisabled(!isStatusDisabled)
          : setIsStatusDisabled(false);
      });
    }
    const selectedStatus = status.map((item) => {
      return item.value;
    });
    let finalStatus = selectedStatus.join("|");
    const finalStatusWithComma = selectedStatus.join(",");
    setSelectedAgoloNewsStatusWithComma(finalStatusWithComma);
    // if(!finalStatus){
    //   finalStatus = "All"
    // }
    if (status.length === 0) {
      finalStatus = "All";
    }
    setSelectedAgoloNewsStatus(finalStatus);
    if (selectedDate && selectedDate !== "All") {
      getNewsBySelectedDate(selectedDate, finalStatus);
    } else {
      callApi(ltWeeklyDate, finalStatus);
    }
  };

  const StatusOptionsForAll = [
    { value: "All", label: "All Status", isDisabled: isAllStatusDisabled },
    { value: "Pending", label: "Draft", isDisabled: isStatusDisabled },
    { value: "Saved", label: "Saved", isDisabled: isStatusDisabled },
    { value: "Completed", label: "Approved", isDisabled: isStatusDisabled },
  ];

  // const handleEditorBlur = (e) => {
  //   console.log(e,'blurfocus------>')
  // }

  const getUserName = (i) => {
    const message = userConnectionList?.find((u) => {
      if (u.index === i && userData?.AplicationId?.toString() !== u.id) {
        return u.userName;
      } else {
        return "";
      }
    });
    if (!message) {
      return "";
    } else if (message && message) {
      return message.userName;
    } else {
      return "";
    }
  };

  const getUserColor = (i) => {
    const message = userConnectionList?.find((u) => {
      if (u.index === i && userData?.AplicationId?.toString() !== u.id) {
        return u.userName;
      } else {
        return "";
      }
    });
    if (!message) {
      return "";
    } else if (message && message?.userName) {
      return message.color;
    } else {
      return "";
    }
  };

  // Slect End
  return (
    <>
      <main
        className="main viewother__news-wrapper"
        style={{ paddingBottom: "90px" }}
        role="head"
      >
        <div className="main__header">
          <header className="head">
            <div className="head__container">
              <div className="head__row">
                <div className="head__left">
                  <div className="head__close">
                    <button
                      onClick={() => {
                        connection.send(
                          "DisconnectedUser",
                          connection.connectionId
                        );
                        navigate(`/other-news/${ltWeeklyDate}`);
                      }}
                      className="btn btn__close"
                    >
                      <Close />
                    </button>
                  </div>
                  <div className="head__divider"></div>
                  <div className="head__title">{handleDate(date)}</div>
                </div>
                <div className="head__right">
                  <div className="d-flex align-items-center me-2">
                    <lablel
                      style={{ marginLeft: "15px" }}
                      className="me-2 custom-label"
                    >
                      {" "}
                      Date Added
                    </lablel>
                    <Select
                      defaultValue={{ value: "All", label: "All" }}
                      className="react-select-container min-width-select"
                      placeholder="All News"
                      name="newsArticle"
                      // closeMenuOnSelect={false}
                      options={[{ value: "All", label: "All" }, ...groupDates]}
                      onChange={handleDateSelection}
                    />
                  </div>

                  {localStorage.getItem("articleStatus") === "All" ||
                  localStorage.getItem("articleStatus") === "all" ? (
                    <div style={{ marginRight: "15px" }}>
                      <Select
                        defaultValue={[StatusOptionsForAll[0]]}
                        isMulti
                        className="react-select-container"
                        placeholder="All Status"
                        isClearable={false}
                        name="status"
                        closeMenuOnSelect={false}
                        onChange={(e) => handleStatus(e)}
                        options={StatusOptionsForAll}
                      />
                    </div>
                  ) : !localStorage.getItem("articleStatus") ||
                    (localStorage
                      .getItem("articleStatus")
                      .includes("Completed") &&
                      localStorage
                        .getItem("articleStatus")
                        .includes("Pending") &&
                      localStorage
                        .getItem("articleStatus")
                        .includes("Saved")) ? (
                    <div style={{ marginRight: "15px" }}>
                      <Select
                        defaultValue={[
                          StatusOptionsForAll[1],
                          StatusOptionsForAll[3],
                          StatusOptionsForAll[2],
                        ]}
                        isMulti
                        className="react-select-container"
                        placeholder="All Status"
                        isClearable={false}
                        name="status"
                        closeMenuOnSelect={false}
                        onChange={(e) => handleStatus(e)}
                        options={StatusOptionsForAll}
                      />
                    </div>
                  ) : localStorage.getItem("articleStatus") === "Completed" ? (
                    <div style={{ marginRight: "15px" }}>
                      <Select
                        defaultValue={[StatusOptionsForAll[3]]}
                        isMulti
                        className="react-select-container"
                        placeholder="All Status"
                        isClearable={false}
                        name="status"
                        closeMenuOnSelect={false}
                        onChange={(e) => handleStatus(e)}
                        options={StatusOptionsForAll}
                      />
                    </div>
                  ) : localStorage.getItem("articleStatus") === "Pending" ? (
                    <div style={{ marginRight: "15px" }}>
                      <Select
                        defaultValue={[StatusOptionsForAll[1]]}
                        isMulti
                        className="react-select-container"
                        placeholder="All Status"
                        isClearable={false}
                        name="status"
                        closeMenuOnSelect={false}
                        onChange={(e) => handleStatus(e)}
                        options={StatusOptionsForAll}
                      />
                    </div>
                  ) : localStorage.getItem("articleStatus") === "Saved" ? (
                    <div style={{ marginRight: "15px" }}>
                      <Select
                        defaultValue={[StatusOptionsForAll[2]]}
                        isMulti
                        className="react-select-container"
                        placeholder="All Status"
                        isClearable={false}
                        name="status"
                        closeMenuOnSelect={false}
                        onChange={(e) => handleStatus(e)}
                        options={StatusOptionsForAll}
                      />
                    </div>
                  ) : localStorage.getItem("articleStatus").includes("Saved") &&
                    localStorage
                      .getItem("articleStatus")
                      .includes("Pending") ? (
                    <div style={{ marginRight: "15px" }}>
                      <Select
                        defaultValue={[
                          StatusOptionsForAll[2],
                          StatusOptionsForAll[1],
                        ]}
                        isMulti
                        className="react-select-container"
                        placeholder="All Status"
                        isClearable={false}
                        name="status"
                        closeMenuOnSelect={false}
                        onChange={(e) => handleStatus(e)}
                        options={StatusOptionsForAll}
                      />
                    </div>
                  ) : localStorage.getItem("articleStatus").includes("Saved") &&
                    localStorage
                      .getItem("articleStatus")
                      .includes("Completed") ? (
                    <div style={{ marginRight: "15px" }}>
                      <Select
                        defaultValue={[
                          StatusOptionsForAll[2],
                          StatusOptionsForAll[3],
                        ]}
                        isMulti
                        className="react-select-container"
                        placeholder="All Status"
                        isClearable={false}
                        name="status"
                        closeMenuOnSelect={false}
                        onChange={(e) => handleStatus(e)}
                        options={StatusOptionsForAll}
                      />
                    </div>
                  ) : (
                    localStorage.getItem("articleStatus").includes("Pending") &&
                    localStorage
                      .getItem("articleStatus")
                      .includes("Completed") && (
                      <div style={{ marginRight: "15px" }}>
                        <Select
                          defaultValue={[
                            StatusOptionsForAll[1],
                            StatusOptionsForAll[3],
                          ]}
                          isMulti
                          className="react-select-container"
                          placeholder="All Status"
                          isClearable={false}
                          name="status"
                          closeMenuOnSelect={false}
                          onChange={(e) => handleStatus(e)}
                          options={StatusOptionsForAll}
                        />
                      </div>
                    )
                  )}
                  {/* <button
                    onClick={() => updateOtherNews()}
                    type='button'
                    disabled={agoloNewsData?.length > 0 ? false : true}
                    className='btn btn__purple'
                  >
                    {isLoading ? <Spin indicator={antIcon} /> : 'Save'}
                  </button> */}
                </div>
              </div>
            </div>
          </header>
        </div>
        <div className="custom__container">
          <div className="all__slect"></div>
          {/*          // <div className="table-responsive table__responsive--custom overlayflow-y-auto">*/}
          <div className="table__responsive--custom">
            <table className="table table__custom">
              <thead>
                <tr>
                  {/* <th className="table__selection--column">Description</th> */}
                  {/* <th className='table__selection--column'>Article</th> */}
                  <th className="table__selection--column"></th>
                  {/* <th>Source</th> */}
                  {/* <th>Tags</th> */}
                  <th></th>
                  {/* <th>Status</th> */}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loader ? (
                  <Spin
                    size="large"
                    indicator={
                      <LoadingOutlined
                        style={{ fontSize: 30, color: "#000" }}
                        spin
                      />
                    }
                    className="loader__full"
                  />
                ) : (
                  agoloNewsData?.length > 0 &&
                  agoloNewsData?.map((an, i) => {
                    // const userColor = getUserColor(an.Id)
                    return (
                      <tr
                        key={i}
                        style={{
                          border: getUserColor(an.Id)
                            ? `3px solid ${getUserColor(an.Id)}`
                            : "",
                          pointerEvents: getUserColor(an.Id) ? "none" : "",
                          position: "relative",
                          backgroundColor: getUserColor(an.Id) ? "#EBEBE4" : "",
                        }}
                      >
                        {/* //   <tr */}
                        {/* //   key={i}
                    //   style={{ */}
                        {/* //     border: userColor ? `3px solid ${userColor}` : "", // Use userColor here
                    //     pointerEvents: userColor ? "none" : "",
                    //     position: "relative",
                    //     backgroundColor: userColor ? "#EBEBE4" : ""
                    //   }}
                    // > */}

                        <td
                          className="table-description"
                          style={{
                            width: "35%",
                          }}
                        >
                          {getUserColor(an.Id) && (
                            <div
                              className="socket-title"
                              style={{
                                backgroundColor: `${getUserColor(an.Id)}`,
                              }}
                            >
                              {getUserName(an.Id)}
                            </div>
                          )}
                          {/* {userColor && 
            <div className='socket-title' style={{ backgroundColor: `${userColor}` }}>
              {getUserName(an.Id)}
            </div>
          } */}

                          <th className="">Description</th>
                          <tr className="table-editor-block editor-page--container">
                            <style>
                              {`  .ck-editor__editable {
                                  min-height: 300px !important;
                                }
                                `}
                            </style>

                            <CKEditor
                              editor={Editor}
                              data={an?.Description}
                              config={custom_config}
                              onFocus={() => handleEditorFocus(i)}
                              onBlur={() => handleEditorBlur(i)}
                              // onChange={handleChangeData(an, i, 'Description', "event", "editor")}
                              // onChange={(event, editor) => handleChangeData(an, i, 'Description', event, editor)}
                              onChange={(event, editor) =>
                                handleChangeData(
                                  an,
                                  i,
                                  "Description"
                                )(event, editor)
                              } // Note the additional parentheses here
                            />
                            <div className="text-end mt-1">
                              {
                                an?.Description?.replaceAll("<strong>", "")
                                  .replaceAll("</b>", "")
                                  .replaceAll("<b>", "")
                                  .replaceAll("</strong>", "")
                                  .replaceAll("<p>", "")
                                  .replaceAll("</p>", "").length
                              }
                            </div>
                          </tr>

                          <tr className="">
                            {/* {an.Source.toUpperCase()} */}
                          </tr>
                          <br />

                          {/* <tr className='add-btn'>
                            <a
                              className='add-btn'
                              target={'_blank'}
                              href={an.Url}
                            >
                              {an.Url}
                            </a>
                          </tr> */}
                          <br />
                        </td>
                        <td>
                          <th className="">Search Tags</th>
                          <div className="tag__group">
                            <input
                              className="react-select-container form-control"
                              name="searchTags"
                              type="text"
                              value={searchTags[i]}
                              onChange={(e) => searchTagText(e, tags, i)}
                              onFocus={() => handleFocusSearch(i)}
                              onBlur={() => handleBlurSearch(i)}
                            />
                          </div>
                          <br />
                          <div className="table-list-container">
                            <ul>{getSearchTags(an, i)}</ul>
                          </div>
                          <th className="">Curated List</th>
                          <div className="tag__group">
                            <Select
                              className="react-select-container"
                              classNamePrefix="react-select"
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              defaultValue={getModifyCuratedList(an.Curated)}
                              isMulti
                              key={an.Curated}
                              onChange={(e) => addCuratedList(e, an, i)}
                              options={curatedList}
                            />
                          </div>
                          <br />

                          <MetaSection
                            articleUrl={an.Url}
                            titleValue={an.Title}
                            sourceValue={an.Source}
                            onTitleChange={(e) =>
                              handleChange(i, e.target.value, "Title")
                            }
                            onSourceChange={(e) =>
                              handleChange(i, e.target.value, "Source")
                            }
                            index={i}
                            onFocusChange={() => handleFocus(i)}
                            onBlurChange={() => handleBlur(i)}
                          />

                          <tr></tr>
                        </td>
                        {/* <td>
                          <th>Primary Tag</th>
                          <tr>
                            {an.Category === null || an.Category === ""
                              ? "-"
                              : an.Category?.split("|")[0]}
                          </tr>
                          <br />
                          <th>Secondary Tag</th>
                          <tr>
                            {an.SecondaryCategory === null ||
                            an.SecondaryCategory === ""
                              ? "-"
                              : an.SecondaryCategory !== "" &&
                                an.SecondaryCategory
                              ? an.SecondaryCategory?.split("|")?.map((sc) => {
                                  return <p>{sc}</p>;
                                })
                              : an.Category?.split("|")?.map((sc, i) => {
                                  if (i > 0) {
                                    return <p>{sc}</p>;
                                  }
                                })}
                          </tr>
                        </td> */}
                        <td className="table__status">
                          <div className="table-radio-container-list">
                            <div className="custom__radio__group">
                              {StatusOptions.map((j) => {
                                return (
                                  <div className="form--inline-radio">
                                    <label className="custom__radio">
                                      {" "}
                                      {j.label}
                                      <input
                                        className="custom__radio--input"
                                        type="radio"
                                        checked={an.Status === j.value}
                                        onChange={() => {
                                          if (
                                            an.Category !== "" &&
                                            an.Category !== null &&
                                            an.Category !== undefined
                                          ) {
                                            handleSave(an, i, j.value);
                                            onChangeStatus(
                                              j.value,
                                              j.label,
                                              an,
                                              i
                                            );
                                          } else {
                                            openNotificationWithIcon(
                                              "error",
                                              "Please Select Primary Category"
                                            );
                                          }
                                        }}
                                      />
                                      <span className="custom__radio--check"></span>
                                    </label>
                                  </div>
                                );
                              })}
                              {/* <div className="form--inline-radio">
                                <label className="custom__radio lg">
                                  <input
                                    className="custom__radio--input"
                                    type="radio"
                                  />
                                  <span className="custom__radio--check lg"></span>
                                  Saved
                                </label>
                              </div>
                              <div className="form--inline-radio">
                                <label className="custom__radio lg">
                                  <input
                                    className="custom__radio--input"
                                    type="radio"
                                  />
                                  <span className="custom__radio--check lg"></span>
                                  Approved
                                </label>
                              </div> */}
                            </div>
                          </div>
                          {/* <br /> */}
                          <tr>
                            <td className="p-0">
                              <button
                                onClick={() => {
                                  setDeleteNewsModal(true);
                                  setIsLoading(false)
                                  setDeletedAgoloNews(an);
                                }}
                                className="remove-btn"
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                          <br />
                          <td className="p-0">
                            <p className="m-0">Primary Tag</p>
                            <p className="m-0">
                              {an.Category === null || an.Category === ""
                                ? "-"
                                : an.Category?.split("|")[0]}
                            </p>
                            <br />
                            <p className="m-0">Secondary Tag</p>
                            <p className="m-0">
                              {an.SecondaryCategory === null ||
                              an.SecondaryCategory === ""
                                ? "-"
                                : an.SecondaryCategory !== "" &&
                                  an.SecondaryCategory
                                ? an.SecondaryCategory?.split("|")?.map(
                                    (sc) => {
                                      return <p className="m-0">{sc}</p>;
                                    }
                                  )
                                : an.Category?.split("|")?.map((sc, i) => {
                                    if (i > 0) {
                                      return <p>{sc}</p>;
                                    }
                                  })}
                            </p>
                            {an.SecondaryCategory?.split("|").length > 0 && (
                              <div style={{ margin: "10px 0" }}></div>
                            )}
                          </td>
                          {/* <br/> */}
                          <button
                            onClick={() => {
                              if (
                                an.Category !== "" &&
                                an.Category !== null &&
                                an.Category !== undefined
                              ) {
                                updateOtherNews(an, i);
                                // onChangeStatus(
                                //   j.value,
                                //   j.label,
                                //   an,
                                //   i
                                // );
                              } else {
                                openNotificationWithIcon(
                                  "error",
                                  "Please Select Primary Category"
                                );
                              }
                            }}
                            type="button"
                            disabled={agoloNewsData?.length > 0 ? false : true}
                            className="btn btn__purple"
                          >
                            {saveLoader[i] ? (
                              <Spin indicator={antIcon} />
                            ) : (
                              "Save"
                            )}
                          </button>
                          <br />
                          <div
                            style={{
                              marginTop: "5px",
                              color: "#ec4e0f",
                              padding: "5px",
                            }}
                          >
                            *Please click this save button if you want
                            <br />
                            to assign this news to the current LTWeekly
                          </div>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
          {agoloNewsData?.length === 0 && !loader && (
            <div className="table__blank">
              <div className="table__blank--text">No Data</div>
            </div>
          )}
        </div>
        <ReactModal
          isOpen={deleteNewsModal}
          onRequestClose={closeNewsModal}
          ariaHideApp={false}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title ">Delete Other News</h5>
            </div>
            <div className="react-modal-body">
              <p className="react-modal-body-text">
                Are you sure you want to delete this other news article? This
                action cannot be undone.
              </p>
            </div>
            <div className="react-modal-footer">
              <button onClick={closeNewsModal} className="btn btn__default">
                Cancel
              </button>
              <button
                onClick={() => removeOtherNews()}
                className="btn btn__orange ms-2"
              >
                {isLoading ? <Spin indicator={antIcon} /> : "Yes, Delete"}
              </button>
            </div>
          </div>
        </ReactModal>
      </main>
    </>
  );
}

const SourceTitleToggle = ({ label, onInputChange, inputValue }) => {
  const [toggle, setToggle] = useState(false);

  return (
    <>
      <th
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span
          className="add-btn"
          onClick={() => {
            setToggle(!toggle);
          }}
          style={{
            cursor: "pointer",
          }}
        >
          {label}
        </span>
        <button
          style={{
            backgroundColor: "#4D1B6C",
            border: "none",
            outline: "none",
            color: "#fff",
            borderRadius: "50%",
            height: "24px",
            width: "24px",
            fontSize: "14px",
            transform: toggle ? "rotate(45deg)" : "",
          }}
          onClick={() => {
            setToggle(!toggle);
          }}
        >
          +
        </button>
      </th>
      {toggle && (
        <textarea
          rows={1}
          name="title"
          onChange={onInputChange}
          className="form-control"
          value={inputValue}
          required
        />
      )}
    </>
  );
};

const MetaSection = ({
  articleUrl,
  titleValue,
  onTitleChange,
  sourceValue,
  onSourceChange,
  onFocusChange,
  onBlurChange,
  index,
}) => {
  const [currentToggle, setCurrentToggle] = useState("none"); // none, title, source

  const openTitleToggle = () => {
    if (currentToggle === "title") {
      setCurrentToggle("none");
    } else {
      setCurrentToggle("title");
    }
  };
  const openSourceToggle = () => {
    if (currentToggle === "source") {
      setCurrentToggle("none");
    } else {
      setCurrentToggle("source");
    }
  };

  return (
    <div style={{ marginBottom: 12 }}>
      <th
        className="add-btn"
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingRight: "64px",
          alignItems: "center",
          marginBottom: 8,
        }}
      >
        <a
          className="add-btn"
          target={"_blank"}
          href={articleUrl}
          rel="noreferrer noopener"
          title={articleUrl}
        >
          Article Url
        </a>

        <div>
          <span
            className="add-btn"
            onClick={openTitleToggle}
            style={{ cursor: "pointer" }}
          >
            Title
          </span>

          <button
            style={{
              backgroundColor: "#4D1B6C",
              border: "none",
              outline: "none",
              color: "#fff",
              borderRadius: "50%",
              height: "24px",
              width: "24px",
              fontSize: "14px",
              marginLeft: "8px",
              transform: currentToggle === "title" ? "rotate(45deg)" : "",
            }}
            onClick={openTitleToggle}
          >
            +
          </button>
        </div>

        <div>
          <span
            className="add-btn"
            onClick={openSourceToggle}
            style={{ cursor: "pointer" }}
          >
            Source
          </span>

          <button
            style={{
              backgroundColor: "#4D1B6C",
              border: "none",
              outline: "none",
              color: "#fff",
              borderRadius: "50%",
              height: "24px",
              width: "24px",
              fontSize: "14px",
              marginLeft: "8px",
              transform: currentToggle === "source" ? "rotate(45deg)" : "",
            }}
            onClick={openSourceToggle}
          >
            +
          </button>
        </div>
      </th>

      {currentToggle === "title" && (
        <div>
          <th className="">Title</th>

          <textarea
            rows={1}
            name="title"
            onChange={onTitleChange}
            className="form-control"
            value={titleValue}
            required
            onFocus={onFocusChange}
            onBlur={onBlurChange}
          />
        </div>
      )}

      {currentToggle === "source" && (
        <div>
          <th className="">Source</th>

          <textarea
            rows={1}
            name="source"
            onFocus={onFocusChange}
            onBlur={onBlurChange}
            onChange={onSourceChange}
            className="form-control"
            value={sourceValue}
            required
          />
        </div>
      )}
    </div>
  );
};
