import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Close } from "../../components/svgIcons";
import { Spin,notification } from "antd";
import { LoadingOutlined, ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { get,size, sortBy,isEmpty } from "lodash";
import moment from "moment";
import SelectReact from "react-select";
import makeAnimated from "react-select/animated";
import ReactModal from "react-modal";

export default function AdminComponent(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [tab, setTab] = useState("all");
  const [nextStep, setNextStep] = useState(0);
  const [loader, setLoader] = useState(true);
  const [isCalled, setIsCallApi] = useState(false);
  const [stateData, setStateData] = useState("");
  const [catId, setCatId] = useState("")
  const [dataList, setDataList] = useState({});
  const [sort, setSort] = useState(false);
  const [themeCategoriesData, setThemeCategoriesData] = useState([]);
  const [type, setType] = useState(-1);
  const [allCheckedTheme, setAllCheckedTheme] = useState(true);
  const [selectedthemes, setSelectedThemes] = useState([]);
  const [secondaryTag, setSecondaryTag] = useState([]);
  const [bulkErrorMessage, setErrorBulktag] = useState("");
  const [isChanged, setIsChanged] = useState(false);
  const [dataListNew, saveDataList] = useState([]);
  const [lTWeeklyThemeData, setLtWeeklyThemeData] = useState(true);
  const [primaryModalIsOpen, setPrimaryIsOpen] = useState(false);
  const [secondaryModalIsOpen, setSecondaryIsOpen] = useState(false);
  const [bulkPrimaryTag, setBulkPrimaryTag] = useState({});
  const [tagLoader, setTagLoader] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [bulkSecondaryTag, setBulkSecondayTag] = useState([]);
  let selectedThemeCategories = [];
  const [LTWeeklyThemePage, setLtWeeklyThemePage] = useState(1);
  const [ltWeeklyNextStep, setLtWeeklyNextStep] = useState(0);
  const [searchTags, setSearchTags] = useState([]);
  const [searchtagIndex,setSearchTagIndex]=useState("")
  const [singleCategoryModalIsOpen,setSingleCategoryModalIsOpen] = useState(false)
  const [primaryRemoveModalIsOpen, setRemovePrimaryIsOpen] = useState(false);
  const [secondaryRemoveModalIsOpen, setRemoveSecondaryIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
 
  
// 2 other news
// 01 themes

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);
const openNotificationWithIcon = (type, message) => {
  notification[type]({
    key: "updatable",
    message: "Liontree",
    description: message,
  });
};
  const callApi = async (name, sort=false, t=-1) => {
    const { value } = await props.getThemeAndOtherNewByName({ titleCategory: name, page: page, count: 100, sort: sort, type: t });
    if(value?.length > 0){
      setDataList(value);
      let stINt = setInterval(()=>{
       setLoader(false);
       clearInterval(stINt)
    },3000)
    }else if(value?.length === 0){
      setDataList(value);
      let stINt = setInterval(()=>{
       setLoader(false);
       clearInterval(stINt)
    },3000)
    }
  };

  const animatedComponents = makeAnimated();
  const getLtWeeklyThemeCategoriesApi = async () => {
    const { value } = await props.getLtWeeklyThemeCategories();
    if (value?.Result?.length > 0) {
      value.Result = sortBy(value.Result, (e) => e.Name?.toLowerCase());
      let tagName = location?.pathname?.split("/")[2]
      let tagn = location?.pathname?.split("/")[3]
      tagName = tagName?.replaceAll("_"," ")?.replaceAll("--","/")
      let data = value?.Result?.find((it)=>it.Title === tagName)
      setCatId(data.Id)
      setThemeCategoriesData(value?.Result);
      let list = [];
      let prm = value?.Result?.map((r) => {
        let v = {
          value: r.Id,
          label: r.Title,
          color: "#00B8D9",
          isFixed: false,
        };
        list.push(v);
      });
      await Promise.all(prm);
      let updateList = list?.sort((a, b) => a.label.localeCompare(b.label))
      setSecondaryTag([...updateList]);
      let stINt = setInterval(()=>{
        setLoader(false);
        clearInterval(stINt)
     },2000)
      // setPrimaryTag(value?.Result);
      // setLoader(false);
    }
  };
  
  const changeTab = async (tab) => {
    setLoader(true);
    setTab(tab);
    let tagName = location?.pathname?.split("/")[2]
    let tagNew = tagName?.replaceAll("_"," ")?.replaceAll("--","/").replaceAll("&","%26")
    if (tab === "other-news") {
      setSort(false)
      setType(2)
      setPage(1);
      callApi(tagNew, false, 2)
      callCountApi(tagNew, 2)
      navigate(`/iim-tags/${tagName}/other-news`);
    } else if (tab === "themes") {
      setType(1)
      setSort(false)
      setPage(1);
      callApi(tagNew, false, 1)
      callCountApi(tagNew, 1)
      navigate(`/iim-tags/${tagName}/themes`);
    }else {
      setSort(false)
      setType(-1)
      setPage(1);
      callApi(tagNew, false, -1)
      callCountApi(tagNew, -1)
      navigate(`/iim-tags/${tagName}/all`);
    }
  };


  const pageChange = async (value) => {
    setLoader(true);
    if (value === "next") {
      let pageNum = page + 1;
      setPage(pageNum);
      const { value } = await props.getThemeAndOtherNewByName({ titleCategory: stateData, page: pageNum, count: 100, sort: sort, type: type  });
      setDataList(value);
    } else if (value === "previous") {
      let pageNum = page - 1 === 0 ? 1 : page - 1;
      setPage(pageNum);
      const { value } = await props.getThemeAndOtherNewByName({ titleCategory: stateData, page: pageNum, count: 100, sort: sort, type: type  });
      setDataList(value);
    }
    setLoader(false);
  };

  const callCountApi = async (name, t=-1) => {
    const { value } = await props.GetCategoryCount({titleCategory: name, type: t })
    let nextPageCount = parseInt(Math.ceil(value / 100));
    setNextStep(nextPageCount);
  }

  useEffect(() => {
    if (!isCalled) {
      getLtWeeklyThemeCategoriesApi()
      let tagName = location?.pathname?.split("/")[2]
      let tagn = location?.pathname?.split("/")[3]
      changeTab(tagn)
      setTab(tagn)
      tagName = tagName?.replaceAll("_"," ")?.replaceAll("--","/")
      setStateData(tagName);
      // callApi(tagName);
      // callCountApi(tagName, true, )
      setIsCallApi(true);
    }
  }, [dataList, stateData, bulkErrorMessage]);
  const onThemeCheck = async (theme, key = "single") => {
    if(isChanged){
      setSingleCategoryModalIsOpen(true)
    }
    if (key === "single") {
      const found = selectedthemes?.findIndex((el) => el.id === theme.id);
      if (found === -1) {
        setSelectedThemes([...selectedthemes, theme]);
        if (selectedthemes?.length + 1 === dataList.length) {
          setAllCheckedTheme(false);
        }
      } else {
        selectedthemes.splice(found, 1);
        setAllCheckedTheme(true);
        setSelectedThemes([...selectedthemes]);
      }
    } else {
      const markData = [];
      if (!allCheckedTheme) {
        setAllCheckedTheme(true);
        setSelectedThemes([]);
      } else {
        let themeData = dataList?.map((theme, index) => {
          markData.push(theme);
        });
        await Promise.all(themeData);
        setSelectedThemes(markData);
        setAllCheckedTheme(false);
      }
    }
  };
  const handlePrimaryTag = async () => {
    if (!isEmpty(bulkPrimaryTag)) {
      setTagLoader(true);
      let payloadList = [];
      let prm = selectedthemes?.map(async (item, index) => {
        let primarycatId= item?.Category?.split("|")[0]
    
        let key = null;
        let isLength = item?.Category?.split("|").length > 1 ? true: false
        if (!item.Category?.includes(bulkPrimaryTag?.value?.toString())) {
          key = bulkPrimaryTag?.value?.toString();
          payloadList.push({
            ThemeId: item.id,
            SecondaryTagId: isLength ? item?.Category?.replace(primarycatId+"|","")?.split("|").map(Number) : [],
            PrimaryTagId: key,
            Type:item.Type
          });
        } else {
          key = bulkPrimaryTag?.value?.toString();
          let secc = isLength ? item?.Category?.replace(primarycatId,""): []
          // let secondaryTagId =
          // secc?.split("|")?.length > 1
          //     ? secc?.replace(`${key}|`, "")
          //     : secc.replace(`${key}`, "");
          let secondaryTagId = "";
        if (secc && secc.length > 0) {
          if (secc.split("|").length > 1) {
             secondaryTagId = secc.replace(`${key}|`, "");
           } else {
             secondaryTagId = secc.replace(`${key}`, "");
             }
                } else {
                  secondaryTagId = [];
              }

          let secTag = [];
          let prms = secondaryTagId?.split("|")?.map((sec) => {
            if (sec !== "" && sec?.toString() !== key) {
              secTag.push(sec);
            }
          });
          await Promise.all(prms);
          payloadList.push({
            ThemeId: item.id,
            SecondaryTagId: secTag,
            PrimaryTagId: key,
            Type:item.Type
          });
        }
      });
      await Promise.all(prm);
      for(var i = 0;i<payloadList.length;i++){
        const { value } = await props.updateTagsOtherNewsandThemes([payloadList[i]]);
        if (value?.success) {
          setTagLoader(false);
         
          setSelectedThemes([]);
          setLoader(true);
          setAllCheckedTheme(true);
          setIsSaveLoading(false);
          setIsChanged(false);
          saveDataList([]);
          setSearchTags([])
          setBulkPrimaryTag({});
          setPrimaryIsOpen(false);
          // callGetLtWeeklyThemeAPI(LTWeeklyThemePage);
        }        
      }
      openNotificationWithIcon(
        "success",
        "Updated Primary Tag successfully."
      );
      let tagName = location?.pathname?.split("/")[2]
    let tagNew = tagName?.replaceAll("_"," ")?.replaceAll("--","/")?.replaceAll("&","%26")
        if (tab === "other-news") {
          setSort(false)
          setType(2)
          setPage(1);
          callApi(tagNew, false, 2)
          callCountApi(tagNew, 2)
          navigate(`/iim-tags/${tagName}/other-news`);
        } else if (tab === "themes") {
          setType(1)
          setSort(false)
          setPage(1);
          callApi(tagNew, false, 1)
          callCountApi(tagNew, 1)
          navigate(`/iim-tags/${tagName}/themes`);
        }else {
          setSort(false)
          setType(-1)
          setPage(1);
          callApi(tagNew, false, -1)
          callCountApi(tagNew, -1)
          navigate(`/iim-tags/${tagName}/all`);
        }

    } else {
      setErrorBulktag("Please select at least one category");
    }
  };
 
  const handleSecondaryTag = async (type) => {
    if (bulkSecondaryTag?.length > 0) {
      setErrorBulktag("");
      setTagLoader(true);
      let payloadList = [];
      let primarycatId=""
      let prm = selectedthemes?.map(async (item, index) => {
        primarycatId= item.Category.split("|")[0]
        
        // return;
        let secTag = item?.Category.split("|").length> 1 ? item?.Category.replace(primarycatId+"|","")?.split("|").map(Number) : [];
        if (secTag === undefined) {
          secTag = [];
        }
        let prmtag = bulkSecondaryTag?.map((data) => {
        //   if (
        //     !item?.Category?.includes(data?.value?.toString())
        //   ) {
        //     if(data?.value?.toString() !== "" ){
        //       secTag.push(data?.value?.toString());
        //     }
        //   }
        // });
        const tagValue = data?.value?.toString();
        // Prevent adding the primary tag to the secondary tags list
        if (!secTag.includes(parseInt(tagValue)) && tagValue !== primarycatId) {
          secTag.push(parseInt(tagValue));
        }
      });
        await Promise.all(prmtag);
        payloadList.push({
          ThemeId: item.id,
          SecondaryTagId: secTag,
          PrimaryTagId: primarycatId,
          Type:item.Type
        });
      });
      if (primarycatId ===""||primarycatId==undefined){
        openNotificationWithIcon(
          "error",
          "can not update secondary tag, please select primary tag first."
        );
        setSecondaryIsOpen(false)
        setTagLoader(false)
        return
      }
      await Promise.all(prm);
      for(var i=0;i<payloadList.length;i++){
        const { value } = await props.updateTagsOtherNewsandThemes([payloadList[i]]);
        if (value?.success) {
          setTagLoader(false);
          setIsSaveLoading(false);
          setIsChanged(false);
          saveDataList([]);
          setSearchTags([])
          setAllCheckedTheme(true);
          setSelectedThemes([]);
          setBulkSecondayTag({});
          setPrimaryIsOpen(false);
          setLoader(true);
          setSecondaryIsOpen(false);
          // callGetLtWeeklyThemeAPI(LTWeeklyThemePage);
        }
      }
      openNotificationWithIcon(
        "success",
        "Updated Secondary Tag successfully."
      );
      let tagName = location?.pathname?.split("/")[2]
    let tagNew = tagName?.replaceAll("_"," ")?.replaceAll("--","/")?.replaceAll("&","%26")
        if (tab === "other-news") {
          setSort(false)
          setType(2)
          setPage(1);
          callApi(tagNew, false, 2)
          callCountApi(tagNew, 2)
          navigate(`/iim-tags/${tagName}/other-news`);
        } else if (tab === "themes") {
          setType(1)
          setSort(false)
          setPage(1);
          callApi(tagNew, false, 1)
          callCountApi(tagNew, 1)
          navigate(`/iim-tags/${tagName}/themes`);
        }else {
          setSort(false)
          setType(-1)
          setPage(1);
          callApi(tagNew, false, -1)
          callCountApi(tagNew, -1)
          navigate(`/iim-tags/${tagName}/all`);
        }
      
    } else {
      setErrorBulktag("Please select at least one category");
    }
  };
  const handleRemovePrimaryTag = async () => {
    setIsLoading(true)
    let isSuccess = false;
    let isError = false;
    const type1Items = selectedthemes.filter((item) => item.Type === 1);
  const type2Items = selectedthemes.filter((item) => item.Type === 2);

  const type1Payload = type1Items.map((item) => ({
    ThemeId: item.id,
    SecondaryTagId: null,
    PrimaryTagId: "", 
    Type: 1,
  }));
console.log(type1Payload,"type1")
  const type2Payload = type2Items.map((item) => ({
    ThemeId: item.id,
    SecondaryTagId: null,
    PrimaryTagId: "",
    Type: 2,
  }));
 console.log(type2Payload,"type2")
  if (type1Payload.length > 0) {
    const { value } = await props.RemoveTagsOtherNewsandThemes(type1Payload);
    if (value?.success) {
      setIsLoading(false)
      isSuccess=true
    setSelectedThemes([]);
    primaryRemoveModalClose()
    }
  else{
    setIsLoading(false)
    isError=true
      primaryRemoveModalClose()
       openNotificationWithIcon("error", "Failed to remove primary tags.");
       
       }
      }
  if (type2Payload.length > 0) {
        const { value } = await props.RemoveTagsOtherNewsandThemes(type2Payload);
        if (value?.success) {
          setIsLoading(false)
          isSuccess=true
        setSelectedThemes([]);
        primaryRemoveModalClose()
        }
      else{
        isError=true
        setIsLoading(false)
          primaryRemoveModalClose()
      }
   }
 if(isSuccess){
          openNotificationWithIcon("success", "Primary tags removed successfully.");
   }
  if(isError){
            openNotificationWithIcon("error", "Failed to remove primary tags.");
          }
  
     let tagName = location?.pathname?.split("/")[2]
    let tagNew = tagName?.replaceAll("_"," ")?.replaceAll("--","/")?.replaceAll("&","%26")
        if (tab === "other-news") {
          setSort(false)
          setType(2)
          setPage(1);
          callApi(tagNew, false, 2)
          callCountApi(tagNew, 2)
          navigate(`/iim-tags/${tagName}/other-news`);
        } else if (tab === "themes") {
          setType(1)
          setSort(false)
          setPage(1);
          callApi(tagNew, false, 1)
          callCountApi(tagNew, 1)
          navigate(`/iim-tags/${tagName}/themes`);
        }else {
          setSort(false)
          setType(-1)
          setPage(1);
          callApi(tagNew, false, -1)
          callCountApi(tagNew, -1)
          navigate(`/iim-tags/${tagName}/all`);
        }
  };
  
  const handleRemoveSecondaryTag = async () => {
  let isSuccess=false
  let isError= false
  setIsLoading(true)
  const type1Items = selectedthemes.filter((item) => item.Type === 1);
  const type2Items = selectedthemes.filter((item) => item.Type === 2);


  const type1Payload = type1Items.map((item) => ({
    ThemeId: item.id,
    SecondaryTagId: [], // Secondary tags cleared
    PrimaryTagId: null, // Retain primary tag
    Type: 1,
  }));

  const type2Payload = type2Items.map((item) => ({
    ThemeId: item.id,
    SecondaryTagId: [], // Secondary tags cleared
    PrimaryTagId: null, // Retain primary tag
    Type: 2,
  }));
  if (type1Payload.length > 0) {
    const { value } = await props.RemoveTagsOtherNewsandThemes(type1Payload);
    if (value?.success) {
      isSuccess=true
      setIsLoading(false)
    secondaryRemoveModalClose()
    }
  else{
    isError=true
       setTagLoader(false);
       }
      }
      if (type2Payload.length > 0) {
        const { value } = await props.RemoveTagsOtherNewsandThemes(type2Payload);
        if (value?.success) {
          isSuccess=true
          setIsLoading(false)
        secondaryRemoveModalClose()
       
        }
      else{
        isError=true
           setTagLoader(false);
           }

      }
      if(isSuccess){
        openNotificationWithIcon("success", "Secondary tags removed successfully.");
        }
        if(isError){
          openNotificationWithIcon("error", "Failed to remove secondary tags.");
        }
     let tagName = location?.pathname?.split("/")[2]
     let tagNew = tagName?.replaceAll("_"," ")?.replaceAll("--","/")?.replaceAll("&","%26")
         if (tab === "other-news") {
           setSort(false)
           setType(2)
           setPage(1);
           callApi(tagNew, false, 2)
           callCountApi(tagNew, 2)
           navigate(`/iim-tags/${tagName}/other-news`);
         } else if (tab === "themes") {
           setType(1)
           setSort(false)
           setPage(1);
           callApi(tagNew, false, 1)
           callCountApi(tagNew, 1)
           navigate(`/iim-tags/${tagName}/themes`);
         }else {
           setSort(false)
           setType(-1)
           setPage(1);
           callApi(tagNew, false, -1)
           callCountApi(tagNew, -1)
           navigate(`/iim-tags/${tagName}/all`);
         }
  };
  
  const handlePrimaryThemeCategories = async (i, data, val) => {
    // setErrorBulktag('');
    // setIsChanged(true);
    // let singleThemeData = lTWeeklyThemeData[i];
    // singleThemeData.PrimaryTag = val?.toString();
    // lTWeeklyThemeData[i] = singleThemeData;
    // setLtWeeklyThemeData([...lTWeeklyThemeData]);
   
    setErrorBulktag("");
    setIsChanged(true);
    // let singleThemeData = dataList[i];
    // let primarycatId= singleThemeData?.Category?.split("|")[0]
    // let secondaryCategories = singleThemeData?.Category?.split("|").slice(1)
  
    // singleThemeData.Category = singleThemeData?.Category?.replace(primarycatId,val?.toString());
    // dataList[i] = singleThemeData;
    // setDataList([...dataList]);
    // let payloadList = [];
    // payloadList.push({
    //   ThemeId: data?.id,
    //   SecondaryTagId: null,
    //   PrimaryTagId: val?.toString(),
    //   Type:data.Type
    // });
    
    // let arr = dataListNew;
    // arr.push({
    //   ThemeId: data?.id,
    //   SecondaryTagId: null,
    //   PrimaryTagId: val?.toString(),
    //   Type:data.Type
    // });
    let singleThemeData = dataList[i];
    let primarycatId = singleThemeData?.Category && singleThemeData?.Category?.split("|")[0];
    let secondaryCategories = singleThemeData?.Category && singleThemeData?.Category?.split("|").slice(1).map(Number)
    // Remove from secondary if it's already there
    if (secondaryCategories && secondaryCategories.includes(parseInt(val))) {
        secondaryCategories = secondaryCategories.filter((tag) => tag !== parseInt(val));
    }

    // Set the new primary and keep updated secondary categories
    singleThemeData.Category = val?.toString() + (secondaryCategories?.length > 0 ? "|" + secondaryCategories?.join("|") : "");
    dataList[i] = singleThemeData;
    
    setDataList([...dataList]);

    let payloadList = [{
        ThemeId: data?.id,
        SecondaryTagId: secondaryCategories?.length > 0 ? secondaryCategories : null,
        PrimaryTagId: val?.toString(),
        Type: data.Type
    }];

    let updatedData = dataListNew.filter((d)=>d.ThemeId != singleThemeData?.id)
    let arr = updatedData;
    arr.push(payloadList[0]);
    saveDataList([...arr]);
    // await props.updateTagsOtherNewsandThemes(payloadList);
  };
  const getModifiedSecondaryTag = (data, categories) => {
    let valuList = [];
    let prm = categories?.map((cat) => {
      if (data?.Category?.toString() != cat?.value?.toString()) {
        valuList.push({
          color: "#00B8D9",
          isFixed: data?.Category?.includes(cat?.value) ? true : false,
          label: cat?.label,
          value: cat?.value,
        });
      }
    });
    return valuList?.sort((a, b) => a.label.localeCompare(b.label));
  };
  const getModifySecondayTag = (value) => {
    const val = value?.split("|")||[];
    let valList = [];
    
    let prm = val?.map((e, index) => {
      if(index!==0){
      let isChecked = secondaryTag?.filter((obj) => {
        return obj.value?.toString() === e;
      });
    
      if (isChecked.length > 0) {
        isChecked[0].isFixed = true;
        valList.push(isChecked[0]);
      }
    }
    });
    if (val?.length > 0) Promise.all(prm);
    valList = sortBy(valList, "label");
    // setDefaultSecondaryTags(valList)
    return valList;
  };
  const handleSecondaryThemeCategories = async (i, val,type) => {
    setErrorBulktag("");
    setIsChanged(true);
    // let strtag = [];
    //   strtag.push(val?.toString());
    // });
    // await Promise.all(stag);
    // strtag = strtag?.join("|");
   
    // let singleThemeData = dataList[i];
    // let primarycatId= singleThemeData?.Category?.split("|")[0]
    // let secondaryCategories = singleThemeData?.Category?.split("|").slice(1)
    // if (secondaryCategories.includes(val)) {
    //   secondaryCategories = secondaryCategories.filter((tag) => tag !== val);
    // } else {
    //   secondaryCategories.push(val);
    // }
  
    
    // singleThemeData.Category = primarycatId + "|" + secondaryCategories.join("|");
   
    
    // dataList[i] = singleThemeData;
    
    // setDataList([...dataList]);
    // let payloadList = [];
    // payloadList.push({
    //   ThemeId: singleThemeData?.id,
    //   SecondaryTagId: secondaryCategories.length > 0 ? secondaryCategories : [],
    //   PrimaryTagId: primarycatId,
    //   Type: type
    // });
    // let arr = dataListNew;
    // arr.push({
    //   ThemeId: singleThemeData?.id,
    //   SecondaryTagId: secondaryCategories.length > 0 ? secondaryCategories : [],
    //   PrimaryTagId: primarycatId,
    //   Type:type
    // });
    let singleThemeData = dataList[i];
    let primarycatId = singleThemeData?.Category?.split("|")[0];
    let secondaryCategories = singleThemeData?.Category?.split("|").slice(1).map(Number)
    
    // If the selected secondary is the current primary, switch it to secondary
    if (val === primarycatId) {
        primarycatId = "";  // Clear the primary if switching to secondary
    }

    // Toggle the secondary selection
    if (secondaryCategories.includes(parseInt(val))) {
        secondaryCategories = secondaryCategories.filter((tag) => tag !== parseInt(val));
    } else {
        secondaryCategories.push(parseInt(val));
    }

    // Update category data
    singleThemeData.Category = primarycatId + "|" + secondaryCategories.join("|");
    dataList[i] = singleThemeData;
    
    setDataList([...dataList]);

    let payloadList = [{
        ThemeId: singleThemeData?.id,
        SecondaryTagId: secondaryCategories.length > 0 ? secondaryCategories : [],
        PrimaryTagId: primarycatId,
        Type: type
    }];
    let updatedData = dataListNew.filter((d)=>d.ThemeId != singleThemeData?.id)
    let arr = updatedData;
  
    arr.push(payloadList[0]);
   
    saveDataList([...arr]);
    // await props.updateTagsOtherNewsandThemes(payloadList);
  };
  const saveData = async () => {
    
    const hasMissingPrimary = dataListNew.some((item) => {
      return !item.PrimaryTagId || item.PrimaryTagId === undefined;
    });
  
    // If a primary category is missing, show an error notification and prevent saving
    if (hasMissingPrimary) {
      if(singleCategoryModalIsOpen){
       
        setSelectedThemes([])
        openNotificationWithIcon(
          'error',
          "Oops, Please select at least one primary category for each item."
        );
        singleCategoryModalClose()
        return
      }else{
      openNotificationWithIcon(
        'error',
        "Oops, Please select at least one primary category for each item."
      );
      return; 
    }
  }
    let updatedSearchTags = [...searchTags]
    setIsSaveLoading(true);
    for (var i = 0; i < dataListNew.length; i++) {

      let pay = [dataListNew[i]];
      const { value } = await props.updateTagsOtherNewsandThemes(pay);
      if (dataListNew.length - 1 === i) {
        if (value.success) {
          updatedSearchTags[searchtagIndex]=""
          setSearchTags([...updatedSearchTags])
          setSingleCategoryModalIsOpen(false)
          let tagName = location?.pathname?.split("/")[2]
    let tagNew = tagName?.replaceAll("_"," ")?.replaceAll("--","/").replaceAll("&","%26")
    if (tab === "other-news") {
      setSort(false)
      setType(2)
      setPage(1);
      callApi(tagNew, false, 2)
      callCountApi(tagNew, 2)
      navigate(`/iim-tags/${tagName}/other-news`);
    } else if (tab === "themes") {
      setType(1)
      setSort(false)
      setPage(1);
      callApi(tagNew, false, 1)
      callCountApi(tagNew, 1)
      navigate(`/iim-tags/${tagName}/themes`);
    }else {
      setSort(false)
      setType(-1)
      setPage(1);
      callApi(tagNew, false, -1)
      callCountApi(tagNew, -1)
      navigate(`/iim-tags/${tagName}/all`);
    }
          setIsSaveLoading(false);
          setIsChanged(false);
          saveDataList([]);
          setSearchTags([...updatedSearchTags])
          setSearchTagIndex("")
          openNotificationWithIcon("success", "Tags were saved successfully");
        }
      }
    }
    setIsSaveLoading(false);
  };
  function primaryModalClose() {
    setErrorBulktag("");
    setBulkPrimaryTag({});
    setPrimaryIsOpen(false);
    setAllCheckedTheme(true)
    setSelectedThemes([])
  }

  function secondaryModalClose() {
    setTagLoader(false)
    setErrorBulktag("");
    setBulkSecondayTag({});
    setSecondaryIsOpen(false);
    setAllCheckedTheme(true)
    setSelectedThemes([])
  }
  const addBrTag = (a) => {
    if (!a?.includes("<strong>")) {
      let b = a.slice(0, a.indexOf(". "));
      let c = a.slice(a.indexOf(". ") + 1, a.length);
      let contentString = "<p><b>" + b + ". " + "</b>" + c + "</p>";
      return contentString.replaceAll("<p>", "").replaceAll("</p>", "");
    } else {
      return a;
    }
  };
  

  const getSearchTags = (an, i) => {
    
    if (searchTags[i] === ""||searchTags[i] ===undefined) {
  
      return themeCategoriesData
        ?.sort((a, b) => a?.Title?.localeCompare(b?.Title))
        .map((t, index) => {
          return (
            <li>
              <span>{t?.Title}</span>
              <div className="btn-container-item">
                {/* {an.Category > 0 } */}
                <button
                  name="primary"
                  value={t?.Id}
                  className={
                    t?.Id == an?.Category?.split("|")[0]
                      ? "btn btn__purple"
                      : "btn btn__default"
                  }
                  onClick={(e) => handlePrimaryThemeCategories(i, an,e.target.value)}
                >
                  Primary
                </button>
                <button
                  name="secondary"
                  value={t?.Id}
                  className={
                    an?.Category?.split("|").length > 1 ? an.Category?.replace(`${an?.Category?.split("|")[0]}|`,"").split("|").find((x=>x==t?.Id)) 
                      ? "btn btn__purple"
                      : "btn btn__default"
                      : an.Category?.replace(`${an?.Category?.split("|")[0]}`,"").split("|").find((x=>x==t?.Id)) 
                      ? "btn btn__purple"
                      : "btn btn__default"
                       
                  }
                  onClick={(e) => {
                    handleSecondaryThemeCategories(i, e.target.value, an.Type);
                  }}
                >
                  Secondary
                </button>
              </div>
            </li>
          );
        });
    } else if (searchTags[i] !== "") {

      let condition = new RegExp(searchTags[i], "i");
      var result = themeCategoriesData.filter(function (el) {
        return condition.test(el.Name) || condition.test(el.Title);
      });
      return result
        .map((t, index) => {
          return (
            <li>
              <span>{t?.Title}</span>
              <div className="btn-container-item">
                {/* {an.Category > 0 } */}
                <button
                  name="primary"
                  value={t?.Id}
                  className={
                    t?.Id == an?.Category?.split("|")[0]
                      ? "btn btn__purple"
                      : "btn btn__default"
                  }
                  onClick={(e) => handlePrimaryThemeCategories(i,an, e.target.value)}
                >
                  Primary
                </button>
                <button
                  name="secondary"
                  value={t?.Id}
                  className={
                    an?.Category?.split("|").length > 1 ? an.Category?.replace(`${an?.Category?.split("|")[0]}|`,"").split("|").find((x=>x==t?.Id)) 
                      ? "btn btn__purple"
                      : "btn btn__default"
                      : an.Category?.replace(`${an?.Category?.split("|")[0]}`,"").split("|").find((x=>x==t?.Id)) 
                      ? "btn btn__purple"
                      : "btn btn__default"
                       
                  }
                  onClick={(e) => {
                    handleSecondaryThemeCategories(i, e.target.value,an.Type);
                  }}
                >
                  Secondary
                </button>
              </div>
            </li>
          );
        });
    }
  };
  const searchTagText = async (eve, tags, index) => {
    searchTags[index] = eve.target.value;
    setSearchTagIndex(index)
    setSearchTags([...searchTags]);
  }

  const OpenPrimaryBulkTagModal= ()=>{
    setPrimaryIsOpen(true)
  }
  const OpenSecondaryBulkTagModal= ()=>{
    setSecondaryIsOpen(true)
  }
  const clearSearchTags = () => {
    const clearedTags = searchTags.map(() => "");
  setSearchTags(clearedTags);
  };
  
  const singleCategoryModalClose=()=> {
    setErrorBulktag("")
    setTagLoader(false)
    setSingleCategoryModalIsOpen(false);
    window.location.reload()
  }

  function displayCategoryTitles(itemData, themeCategoriesData) {
    if (!itemData.Category) {
      return {
        primaryTitle: "-",
        secondaryTitles: []
      };
    }
  
    const categories = itemData.Category.split("|");
    const primaryCategoryId = categories[0];
  
    //Primary Cagtegory
    const primaryCategory = themeCategoriesData.find(category => category.Id === parseInt(primaryCategoryId));
  
    let primaryTitle = primaryCategory ? primaryCategory.Title : "-"; 
  
    //Secondary Title
    let secondaryTitles = [];
    for (let i = 1; i < categories.length; i++) {
      const currentCategoryId = categories[i];
      const secondaryCategory = themeCategoriesData.find(category => category.Id === parseInt(currentCategoryId));
  
      if (secondaryCategory) {
        secondaryTitles.push(secondaryCategory.Title);
      }
    }
  
    return {
      primaryTitle,
      secondaryTitles
    };
  }
  
  function primaryRemoveModalClose() {
    setTagLoader(false)
    setErrorBulktag("");
    setRemovePrimaryIsOpen(false);
    setSelectedThemes([])
    setAllCheckedTheme(true)
  }
  function secondaryRemoveModalClose() {
    setTagLoader(false)
    setErrorBulktag("");
    setRemoveSecondaryIsOpen(false);
    setSelectedThemes([])
    setAllCheckedTheme(true)
  }
  const RemovePrimaryBulkTagModal= ()=>{
    setRemovePrimaryIsOpen(true)
  }
  const RemoveSecondaryBulkTagModal= ()=>{
    setRemoveSecondaryIsOpen(true)
  }
  return (
    <>
      <main class="main home__thmes-wrapper" role="header">
        <div className="main__header">
          <header className="head">
            <div className="head__container">
              <div className="head__row">
                <div className="head__left">
                  <div className="head__close">
                    <button
                      onClick={() => navigate("/iim-tags")}
                      className="btn btn__close"
                    >
                      <Close />
                    </button>
                  </div>
                  <div className="head__divider"></div>
                  <div className="head__title">
                    {stateData}
                  </div>
                </div>
              </div>
              
            </div>
          </header>

          </div>
          <div className="page__header--left">
          <div className="inner-tabs-container">
           {/* <button
            className={tab === "all" ? "tabs__link active" : "tabs__link"}
            changes
            onClick={() => changeTab("all")}
          > */}
            <Link
              className={tab === "all" ? "tabs__link active" : "tabs__link"}
              to={`/iim-tags/${location?.pathname?.split("/")[2]}/all`}
              onClick={() => changeTab('all')}
            >
            All
            </Link>
          {/* </button> */}
          {/* <button
          className={tab === "themes" ? "tabs__link active" : "tabs__link"}
          changes
          onClick={() => changeTab("themes")}
        > */}
        <Link
              className={tab === "themes" ? "tabs__link active" : "tabs__link"}
              to={`/iim-tags/${location?.pathname?.split("/")[2]}/themes`}
              onClick={() => changeTab('themes')}
            >
          Themes
        </Link>
        <Link
          className={tab === "other-news" ? "tabs__link active" : "tabs__link"}
              to={`/iim-tags/${location?.pathname?.split("/")[2]}/other-news`}
              onClick={() => changeTab("other-news")}
            >
          Other News
        </Link>
          
        </div>
          {/* <div className="tabs__links tabs__links--space">
          <div className="tabs__links tabs__links--space">
        </div>
        </div> */}
        </div>
        <div className="custom__container">
        <div className="all__slect  sticky-header-container">
              <label className="custom__checkbox">
                <input
                  type="checkbox"
                  className="custom__checkbox--input"
                  checked={!allCheckedTheme}
                  onChange={(e) => onThemeCheck({}, "All")}
                />
                <span className="custom__checkbox--check"></span>
              </label>
              <button
                className="btn btn__purple"
                disabled={!selectedthemes.length}
                onClick={()=>{clearSearchTags();OpenPrimaryBulkTagModal()}}
              >
                Bulk Add Primary Tag
              </button>
              <button
                className="btn btn__purple"
                disabled={!selectedthemes.length}
                onClick={()=>{clearSearchTags();OpenSecondaryBulkTagModal()}}
              >
                Bulk Add Secondary Tag
              </button>
              <button
                className="btn btn__purple"
                disabled={!selectedthemes.length}
                onClick={()=>{clearSearchTags();RemovePrimaryBulkTagModal()}}
              >
                Bulk Remove Primary Tag
              </button>
              <button
                className="btn btn__purple"
                disabled={!selectedthemes.length}
                onClick={()=>{clearSearchTags();RemoveSecondaryBulkTagModal()}}
              >
                Bulk Remove Secondary Tag
              </button>
              {isChanged && (
                      <button
                        className="btn btn__purple"
                        disabled={singleCategoryModalIsOpen}
                        onClick={() => saveData()}
                      >
                        {isSaveLoading ? <Spin indicator={antIcon} /> : "Save"}
                      </button>
                    )}
            </div>
          <div className="table-responsive table__responsive--custom">
            <table className="table table__custom">
              <thead>
                <tr>
                  <th >
                  {sort ?
                    <button className="sort-btn" onClick={()=>{
                      setSort(!sort)
                      callApi(location?.pathname?.split("/")[2]?.replaceAll("_"," ")?.replaceAll("--","/"), !sort, type)
                    }
                    }>IIM Date<ArrowUpOutlined /></button>
                     :
                    <button className="sort-btn" onClick={()=>{setSort(!sort)
                      callApi(location?.pathname?.split("/")[2]?.replaceAll("_"," ")?.replaceAll("--","/"), !sort, type)}}>IIM Date <ArrowDownOutlined /></button>
                    }
                    </th>
                  <th>TYPE</th>
                  {/* <th>PRIMARY TAG</th> */}
                  <th>Theme/Article title</th>
                  {(tab === "other-news" || tab === "all") && (<th>Description</th>)}
                  <th>Search Tags</th>
                </tr>
              </thead>
              <tbody>
                {loader ? (
                  <Spin
                    size="large"
                    indicator={
                      <LoadingOutlined
                        style={{ fontSize: 30, color: "#000" }}
                        spin
                      />
                    }
                    className="loader__full"
                  />
                ) : (
                  dataList?.length > 0 &&
                  dataList?.map((news, i) => {
                    const { primaryTitle, secondaryTitles } = displayCategoryTitles(news, themeCategoriesData);
                    return (
                      <tr key={i}>
                      <td className="table__status">
                        <label className="custom__checkbox">
                              <input
                                name="themes"
                                value={i}
                                checked={
                                  selectedthemes
                                    ?.map((theme) => theme.id)
                                    ?.indexOf(news.id) !== -1
                                }
                                onChange={() => onThemeCheck(news)}
                                type="checkbox"
                                className="custom__checkbox--input"
                              />
                              <span className="custom__checkbox--check"></span>
                              Week ending {moment(new Date(news.Created)).format("MMM DD, YYYY")}
                            </label>
                          </td>
                        <td> {news.ExternalUrl === "" ? "Theme " + news.ItemName : "Other News" }</td>
                        {news.ExternalUrl === "" ? 
                        <td className={(tab==="themes")?"text-btn word-break purple-text other-news-links theme-Link":"text-btn word-break purple-text other-news-links"} dangerouslySetInnerHTML={{ __html: news.ShortDescription }}>
                        </td>
                        : <>
                        <td className="text-btn word-break purple-text other-news-links" dangerouslySetInnerHTML={{ __html: `<a href=${news.ExternalUrl} target="__blank">${news.ShortDescription}</a>` }}>
                        </td>
                        </>}
                        {(tab === "other-news" || tab === "all")?
                        news.Type===2 ?
                        <td className="text-btn word-break purple-text other-news-links text-justify-custom"
                            
                            dangerouslySetInnerHTML={{ __html: addBrTag(news.LongDescription) }}>
  
                          </td>
                          :
                          <>
                          <td className="text-btn word-break purple-text other-news-links text-center">
                                <span> - </span>
                          </td>
                          </>
                          :""}
                        <td className='table__status'>
                                  {/* <div className='form-group'>
                                    <select
                                      style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        paddingRight: '40px',
                                      }}
                                      className='form-control'
                                      //  value={catId}
                                      value={news?.Category?.split("|")[0]}

                                      onChange={(e) => {
                                        handlePrimaryThemeCategories(
                                          i,
                                          news,
                                          e.target.value,
                                          news.Type
                                        )
                                    
                                      }}
                                    >
                                      <option value='0'>Select</option>
                                      {themeCategoriesData ?.sort((a, b) =>
                                    a.Title.localeCompare(b.Title)
                                  )?.map((it) => {
                                          return (
                                            <option value={it.Id}>
                                              {it.Title}
                                            </option>
                                          );
                                        
                                      })}
                                    </select>
                                  </div> */}
                                  <div className="tag__group mb-2">
                            <input
                              className="react-select-container form-control"
                              name="searchTags"
                              type="text"
                              value={searchTags[i]}
                              onChange={(e) => searchTagText(e, secondaryTag, i)}
                            />
                          </div>
                          <div className="table-list-container">
                            <ul>{getSearchTags(news, i)}</ul>
                          </div>
                          <div className="primarySecondaryTag">
                            <p>Primary Tag</p>
                            <span>{primaryTitle || "-"}</span>
                            <p>Secondary Tag</p>
                            <span  style={{  whiteSpace:"normal"}}>{secondaryTitles.length > 0 ? secondaryTitles.join(", ") : "-"}</span> 
                          </div>
                                </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
          {(dataList?.length === 0 && !loader) &&<div className="table__blank">
            <div className="table__blank--text">No Data</div>
          </div> }
          {dataList?.length > 0 && (
            <div className="table__pagination">
              <div className="table__pagination--results">
                {size(dataList)} results
              </div>
              <div className="table__pagination--nav">
                <>
                  {page - 1 >= 1 ? (
                    <button
                      type="button"
                      className="btn btn__default table__pagination--prev"
                      onClick={() => pageChange("previous")}
                    >
                      Previous
                    </button>
                  ) : (
                    <button
                      type="button"
                      disabled
                      className="btn btn__default table__pagination--prev disabled"
                    >
                      Previous
                    </button>
                  )}
                </>
                <>
                  {nextStep - page > 0 ? (
                    <button
                      type="button"
                      className="btn btn__default table__pagination--next"
                      onClick={() => pageChange("next")}
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      type="button"
                      disabled
                      className="btn btn__default table__pagination--next disabled"
                    >
                      Next
                    </button>
                  )}
                </>
              </div>
            </div>
          )}
        </div>
        <ReactModal
          isOpen={primaryModalIsOpen}
          onRequestClose={primaryModalClose}
          contentLabel=""
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          shouldFocusAfterRender={true}
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered addcurated__list-modal"
        >
          <div className="modal-content">
            <div className="react-modal-header border-bottom">
              <h5 className="react-modal-title">Add Primary Tag</h5>
              {/* <button className="btn react-modal-close" onClick={closeModal}>
                <Close />
              </button> */}
            </div>
            <div className="react-modal-body">
              <div className="form-group">
                <label className="label-primary">Primary Tag</label>
                <div className="tag__group">
                  <SelectReact
                    onChange={(e) => {
                      setErrorBulktag("");
                      setBulkPrimaryTag({ ...e });
                    }}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    defaultValue={selectedThemeCategories}
                    //isMulti
                    options={secondaryTag}
                  />
                </div>
                {bulkErrorMessage && (
                  <div className="invalid-feedback">{bulkErrorMessage}</div>
                )}
              </div>
            </div>
            <div className="react-modal-footer">
              <button
                onClick={() => primaryModalClose()}
                type="button"
                className="btn btn__default"
              >
                Cancel
              </button>
              <button
                onClick={() => handlePrimaryTag()}
                type="button"
                className="btn btn__purple"
              >
                {tagLoader ? <Spin indicator={antIcon} /> : "Save"}
              </button>
            </div>
          </div>
        </ReactModal>
        
        <ReactModal
          isOpen={secondaryModalIsOpen}
          onRequestClose={secondaryModalClose}
          contentLabel=""
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          shouldFocusAfterRender={true}
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered addcurated__list-modal"
        >
          <div className="modal-content">
            <div className="react-modal-header border-bottom">
              <h5 className="react-modal-title">Add Secondary Tag</h5>
              {/* <button className="btn react-modal-close" onClick={closeModal}>
                <Close />
              </button> */}
            </div>
            <div className="react-modal-body">
              <div className="form-group">
                <label className="label-primary">Secondary Tag</label>
                <div className="tag__group">
                  <SelectReact
                    onChange={(e) => {
                      setErrorBulktag("");
                      setBulkSecondayTag(e);
                    }}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    defaultValue={selectedThemeCategories}
                    isMulti
                    options={secondaryTag}
                  />
                </div>
                {bulkErrorMessage && (
                  <div className="invalid-feedback">{bulkErrorMessage}</div>
                )}
              </div>
            </div>
            <div className="react-modal-footer">
              <button
                onClick={() => secondaryModalClose()}
                type="button"
                className="btn btn__default"
              >
                Cancel
              </button>
              <button
                onClick={() =>handleSecondaryTag()}
                type="button"
                className="btn btn__purple"
              >
                {tagLoader ? <Spin indicator={antIcon} /> : "Save"}
              </button>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={singleCategoryModalIsOpen}
          onRequestClose={secondaryModalClose}
          contentLabel=""
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          shouldFocusAfterRender={true}
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered addcurated__list-modal"
        >
          <div className="modal-content">
            <div className="react-modal-header border-bottom">
              {/* <h5 className="react-modal-title">Add Secondary Tag</h5> */}
              {/* <button className="btn react-modal-close" onClick={closeModal}>
                <Close />
              </button> */}
            </div>
            <div className="react-modal-body">
              <div className="form-group">
                <label className="label-primary">You have some unsaved changes, do you want to save them before proceeding?</label>
                {bulkErrorMessage && (
                  <div className="invalid-feedback">{bulkErrorMessage}</div>
                )}
               
              </div>
            </div>
            <div className="react-modal-footer">
              <button
                onClick={() => singleCategoryModalClose()}
                type="button"
                className="btn btn__default"
              >
                No
              </button>
              <button
                onClick={() =>saveData()}
                type="button"
                className="btn btn__purple"
              >
                {tagLoader ? <Spin indicator={antIcon} /> : "Yes"}
              </button>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={primaryRemoveModalIsOpen}
          onRequestClose={primaryRemoveModalClose}
          contentLabel=""
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          shouldFocusAfterRender={true}
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered addcurated__list-modal"
        >
            <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title ">Clear Primary Tag</h5>
            </div>
            <div className="react-modal-body">
              <p className="react-modal-body-text">
                Are you sure you want to clear the Primary Tags? This cannot be undone.
              </p>
            </div>
            <div className="react-modal-footer">
              <button onClick={primaryRemoveModalClose} className="btn btn__default">
                Cancel
              </button>
              <button
                onClick={() => handleRemovePrimaryTag()}
                className="btn btn__purple ms-2"
              >
                {isLoading ? <Spin indicator={antIcon} /> : "Yes, Clear"}
              </button>
            </div>
          </div>
        </ReactModal>
        {/* Secondary Remove Bulk Tag */}
        <ReactModal
          isOpen={secondaryRemoveModalIsOpen}
          onRequestClose={secondaryRemoveModalClose}
          contentLabel=""
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          shouldFocusAfterRender={true}
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered addcurated__list-modal"
        >
             <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title ">Clear Secondary Tag</h5>
            </div>
            <div className="react-modal-body">
              <p className="react-modal-body-text">
                Are you sure you want to clear the Secondary Tags? This cannot be undone.
              </p>
            </div>
            <div className="react-modal-footer">
              <button onClick={secondaryRemoveModalClose} className="btn btn__default">
                Cancel
              </button>
              <button
                onClick={() => handleRemoveSecondaryTag()}
                className="btn btn__purple ms-2"
              >
                {isLoading ? <Spin indicator={antIcon} /> : "Yes, Clear"}
              </button>
            </div>
          </div>
        </ReactModal>
      </main>
    </>
  );
}
